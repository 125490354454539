import { useEffect, useState, useRef, Fragment, useContext } from "react";
import { FeedRequestCardDesktop, ProfileRequestCardDesktop } from "./RequestCards";
import { 
    CardDelete,
    CardRecommendation,
    CardRecommendationRequests,
    ChatsRequest, 
    ContactsRequest, 
    EmailAuth, 
    EmailPrefsUpdate, 
    FeedRequest, 
    FirstLoginAdd, 
    GoogleAuth, 
    InviteTokenBurn, 
    InviteTokenUse, 
    LinkedInAuth, 
    MatchRequest, 
    NotificationsRead, 
    OffersRequest, 
    OpenStripePortal, 
    ProfileRequest,  
    RelevanceRequest,  
    SearchRequest, 
    SendInvite, 
    SetReadyStatus, 
    Subscribe, 
    SubscriptionsRequest,
    ToRateRequest,
    ToRateSkip
} from "../Requests";
import { useCookies } from "react-cookie";
import React from "react";
import {    
    NewAvatar,
    Edit, 
    Loading,
    SmallUserBlock,
    HintBlock,
    SiteCardSmall,
    CardMatchesVideo,
} from "../elements/Elements.jsx";
import {   
    PopupRightSettings,
    PopupCenterUnsubscribe,
    PopupCenterLogOut,
    PopupCenter,
    PopupCenterInvite,
    InfoPopup,
    InfoErrorPopup,
    PopupCenterCanHelpLanding,
    PopupCenterDelete,
    ConfirmPopup,
    AddRequestDesktop,
    GradePopupCenter,
} from "../elements/Popups";
import { useNavigate } from "react-router";
import { 
    DateFromString,
    DateTimeFromString, 
    DateToTimestamp, 
    EmailRegExp, 
    GetWebsocketUrl, 
    IsLeftScroll, 
    IsRightScroll, 
    MakeValidURL, 
    OnlyValid, 
    ScreenColors,  
    SetDict, 
    ClipBoard,
    Share,
    Industries,
    CreateHSLColor,
    RequestColors2,
    UntilMatch,
    Until,
} from "../elements/Data";
import { 
    AgreementBlack,
    AngleRightBlack,
    AngleRightBlack2,
    AppleWhite,
    CardBlack,
    ChatWhite,
    CopyBlack,
    ExitBlack,
    EyeBlack,
    FacebookWhite,
    FunnelBlack,
    GearBlack,
    GoogleLogo,
    LensBlack,
    LinkBlack,
    LinkedInLogo,
    LinkedInWhite,
    LocationBrightBlue,
    LocationGray,
    OkWhite,
    PhoneWhite,
    PlusBlack,
    PlusGreen,
    PlusWhite,
    PlusWhite2,
    ProtectBlack,
    SettingsBlack,
    ShareBlack,
    StarYellow,
    SupportBlack,
    TelegramWhite,
    TrashBlack,
    TrashCoral,
    XWhite, 
} from "../elements/Imgs";
import { ChatFieldDesktop, ChatListDesktop, OfferListDesktop } from "./ChatDesktop";
import { MessageCardDesktop } from "./RequestCards";
import { useSearchParams } from "react-router-dom";
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import { SiteCard } from "../elements/Elems";

export const ProfileFeedDesktop = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const context = useContext(ProfileContext);  
    const globalContext = useContext(GlobalContext);
    
    const [ feed, setFeed ] = useState<any[] | null>(null);
    const [ recRequests, setRecRequests ] = useState<any | null>(null);
    // const [ tab, setTab ] = useState<number>(searchParams.get(`first_login`) === `true` ? 1 : 0);
    const [ backFeed, setBackFeed ] = useState<any[] | null>(null);
    // const [ sortOld, setSortOld ] = useState<boolean>(false); 
    const [ rndColor, setRndColor ] = useState<string[]>([]);
    const [ openFilter, setOpenFilter ] = useState<boolean>(false);
    const [ filterByIndustry, setFilterByIndustry ] = useState<string[]>([]);
    const [ filterIndustrySearch, setFilterIndustrySearch ] = useState<string>('');
    const [ filterByRequest, setFilterByRequest ] = useState<number[]>([]);

    // useEffect(() => {
    //     if (!!filterByIndustry?.length || !!filterByRequest?.length) {
    //         setOpenFilter(true);
    //     }
    // }, [filterByRequest?.length, !filterByIndustry?.length])

    const FilterSearchRequests = (requests: any[]) => {
        return requests.filter((val: any) => (
            (filterByIndustry.filter(val_ => (val?.user?.industry ?? []).includes(val_))?.length > 0 || filterByIndustry?.length === 0) &&
            (filterByRequest.includes(val?.card?.type) || filterByRequest?.length === 0)
        ));
    }

    const FilterFeed = (users: any[]) => {
        return users.filter(
            (val: any) => (filterByIndustry.filter(val_ => (val?.industry ?? []).includes(val_))?.length > 0 || filterByIndustry?.length === 0)
        ).map((val: any) => {return {
            ...val,
            requests: (val?.requests ?? []).filter((request: any) => filterByRequest.includes(request?.type) || filterByRequest?.length === 0)
        }});
    }
    
    // const [ hintStep, setHintStep ] = useState<number>(0);
    // const [ hint2Style, setHint2Style ] = useState<{
    //     top?: number | string,
    //     bottom?: number | string,
    //     left?: number | string,
    //     right?: number | string,
    //     tailDirection: string,
    // }>();
    // const [ hint3Style, setHint3Style ] = useState<{
    //     top?: number | string,
    //     bottom?: number | string,
    //     left?: number | string,
    //     right?: number | string,
    // }>();

    const [ feedPage, setFeedPage ] = useState<number>(0);
    const [ moreFeed, setMoreFeed ] = useState<boolean>(true);  
    // const [ morePeople, setMorePeople ] = useState<boolean>(true); 

    // const [ search, setSearch ] = useState<string>(searchParams.get(`search`) ?? ``);
    // const [ showSearch, setShowSearch ] = useState<boolean>(false);
    const [ timer, setTimer ] = useState<any>();
    const [ searchData, setSearchData ] = useState<any>();    
    const [ lastSearch, setLastSearch ] = useState<string>("");
    const [ searchAbortController, setSearchAbortController ] = useState<AbortController>(new AbortController());
    
    // const [ showProfile, setShowProfile ] = useState<any | null>(null);

    const recommendScrollBlock = useRef<HTMLDivElement | null>(null);
    const recommendScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftRecommendBtn, setShowLeftRecommendBtn ] = useState<boolean>(false);
    const [ showRightRecommendBtn, setShowRightRecommendBtn ] = useState<boolean>(false);

    const topScroller = () => {
        if (window.scrollY > document.documentElement.scrollHeight - document.documentElement.clientHeight - 500) {
            setMoreFeed(true);
            // setMorePeople(true);
        }

        const topNavBar = document.querySelector(`#feed-top-navigation`) as HTMLDivElement;
        if (topNavBar) {
            topNavBar.style.boxShadow = window.scrollY > 0 ? "0px 3px 3px -2px rgba(0, 0, 0, 0.14)" : "";
        }
    } 

    const onResize = () => {
        const buttonCanHelp = document.querySelector(`#select-btn-hint`);
        const buttonSuggest = document.querySelector(`#select-btn-hint-2`);
        const feedBlock = document.querySelector(`#feed-block-hint`);
        if (buttonCanHelp && buttonSuggest && feedBlock) {
            // setHint2Style({
            //     top: buttonCanHelp.getBoundingClientRect().bottom - feedBlock.getBoundingClientRect().top + 14,
            //     right: document.documentElement.clientWidth > 1280 ? 
            //         `unset` : feedBlock.getBoundingClientRect().right - buttonCanHelp.getBoundingClientRect().right,
            //     left: document.documentElement.clientWidth > 1280 ? 
            //         buttonCanHelp.getBoundingClientRect().left - feedBlock.getBoundingClientRect().left : `unset`,
            //     tailDirection: document.documentElement.clientWidth > 1280 ? `top-left` : `top-right`,
            // })
            // setHint3Style({
            //     top: buttonSuggest.getBoundingClientRect().bottom - feedBlock.getBoundingClientRect().top + 14,
            //     left: buttonSuggest.getBoundingClientRect().left - feedBlock.getBoundingClientRect().left,
            //     right: `unset`,
            // })
        }
    }

    useEffect(() => {
        if (feed?.length === 0) {
            context.setTab(1);
        }
    }, [feed])
    
    useEffect(() => {
        onResize();
    }, [recRequests])

    useEffect(() => {
        setMoreFeed(false);
    }, [feed?.length ?? 0])

    useEffect(() => {
        if (moreFeed) {
            FeedRequest(cookie['user_id_cookie'], setFeed, setBackFeed, feedPage, () => {
                setFeedPage(val => Math.min(10000, val + 1)); 
            });
        }
    }, [moreFeed])

    useEffect(() => {
        if (context.profile?.about) {
            CardRecommendationRequests(
                cookie[`user_id_cookie`], 
                context.profile?.about,
                setRecRequests,
            )
        } else if (context.profile?.industry?.length > 0) {
            CardRecommendationRequests(
                cookie[`user_id_cookie`], 
                context.profile?.industry.join(","),
                setRecRequests,
            )
        }
    }, [context.profile?.about, context.profile?.industry])

    useEffect(() => {        
        document.addEventListener('scroll', topScroller); 
        window.addEventListener("resize", onResize);

        context.setGlobalSearch(searchParams.get("search") ?? "");

        return () => {
            document.removeEventListener('scroll', topScroller);
            window.removeEventListener("resize", onResize);
            context.setGlobalSearch("");
        }
    }, [])  
    
    useEffect(() => {
        const hist = JSON.parse(localStorage.getItem(`search_history`) ?? '[]');
        setLastSearch(hist[0] ?? "");
    }, [searchData])
    
    useEffect(() => {
        clearTimeout(timer);
        searchAbortController.abort();
        const newAbortController = new AbortController();
        setSearchAbortController(newAbortController);
        if (!!context.globalSearch?.length || !!filterByIndustry?.length || !!filterByRequest?.length) {
            setTimer(setTimeout(() => {
                SearchRequest(
                    context.globalSearch ?? "", 30, setSearchData, 
                    filterByIndustry?.length > 0 ? filterByIndustry : null,
                    filterByRequest?.length > 0 ? filterByRequest : null,
                    newAbortController.signal,
                );
            }, 500))
        } else {
            setSearchData(undefined);
        }
    }, [context.globalSearch, filterByIndustry, filterByRequest])
    
    useEffect(() => {       
        if (recRequests && !(context.profile?.first_login ?? [`feed`]).includes(`feed`)) {
            setSearchParams({first_login: `true`});
            FirstLoginAdd(cookie[`user_id_cookie`], `feed`);
            // document.documentElement.style.overflowY = 'hidden';
        }


    }, [props.profile, recRequests])

    useEffect(() => {     
        if (props.recommendationsCommon?.length > 0) { 
            setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);
        }
    }, [props.recommendationsCommon?.length])    

    // useEffect(() => {
    //     if (rndColor) {
    //         const btn = document.querySelector(`#footer-button-color`) as HTMLButtonElement;
    //         // console.warn(`feed changed`, rndColor[0])
    //         if (btn) {
    //             btn.setAttribute(`footer-color`, rndColor[0] ?? ``);
    //             btn.click();
    //         }
    //     }
    // }, [rndColor])

    return (
        <div className={`w-flex column`}>
            <div className={`w-10 sm-w-flex`}>
            {!!props.profile ?
            <div className={`w-flex`}>
                {sessionStorage.getItem("first_login") === `true` && false &&
                <div className={`w-flex b-3 p-2 pt-3 mb-2 md-mb-3`} style={{backgroundColor: `#AAEBD4`}}>
                    <div className={`w-flex`} style={{padding: `0 10px`}}>
                        <p data-size={5} data-color={`dark-green`} className={`semibold seminarrow mb`}>{`Suggested requests`}</p>
                        <p data-size={6} data-color={`dark-green`} className={`mb-2`}>
                            {`These are the requests we've curated for you based on your professional background and bio. Discover who could benefit greatly from your expertise.`}
                        </p>
                    </div>
                    <div className={`row left gap-0 w-flex md-grid-2`}>
                        <div className={`block p-2 b-2 w-flex md-h-flex`} data-color={`light-green`}>
                            <p data-size={8} data-color={`dark-green`} className={`semibold seminarrow mb`}>
                                {`I can help`}
                            </p>
                            <p data-size={6} data-color={`dark-green`}>
                                {`Notify the person of your ability to aid them and share further details through a private message.`}
                            </p>
                        </div>
                        <div className={`block p-2 b-2 w-flex md-h-flex`} data-color={`light-green`}>
                            <p data-size={8} data-color={`dark-green`} className={`semibold seminarrow mb`}>
                                {`Suggest a friend`}
                            </p>
                            <p data-size={6} data-color={`dark-green`}>
                                {`Do you have connections who could assist? Share the request and simplify the intro process.`}
                            </p>
                        </div>
                    </div>
                </div>}

                <div className={`w-flex`}> 
                    <div className={`w-flex`} hidden={true}>               
                        {!!props.profile?.about && props.profile?.requests?.length > 0 && props.profile?.projects?.length === 0 &&
                        <div className={`label block btn-block-new desktop w-flex mb-2`} 
                                data-color={`extra-light-coral`} data-border={`coral-3`} data-md-hidden={true}>
                            <p>{`Add at least one project that you have completed`}</p>
                            <p data-size={6} className={`regular mt`}>
                                {`Projects help other people understand who you are.`}
                            </p>
                            <button className={`block btn-block-new desktop w-flex mt-2 row nowrap`} onClick={() => {
                                    context.setAddProjectPopup(true);
                                }} style={{padding: 14, borderRadius: 14}}>
                                <p>{`Add a project`}</p>
                                <div className={`btn-symbol h-3 w-3 centered`}>
                                    <img src={PlusBlack} alt={`+`}></img>
                                </div>
                            </button>
                        </div>}

                        {props.profile?.requests?.length > 0 && !props.profile?.about &&
                        <div className={`label block btn-block-new desktop w-flex mb-2`} 
                                data-color={`extra-light-coral`} data-border={`coral-3`} data-md-hidden={true}>
                            <p>{`Tell about yourself`}</p>
                            <p data-size={6} className={`regular mt`}>
                                {`Introduce yourself in a way that will pique the interest of the people you are looking for. `}
                            </p>
                            <button className={`block btn-block-new desktop w-flex mt-2 row nowrap`} onClick={() => {
                                    context.setPage(0, 3);
                                    context.setTab(3, 0);
                                }} style={{padding: 14, borderRadius: 14}}>
                                <p>{`Add about`}</p>
                                <div className={`btn-symbol h-3 w-3 centered`}>
                                    <img src={PlusBlack} alt={`+`}></img>
                                </div>
                            </button>
                        </div>}

                        {props.profile?.requests?.length === 0 ?
                        <div className={`label block btn-block-new desktop w-flex`} 
                        data-color={`extra-light-coral`} data-border={`coral-3`}>
                            <p>{`Post at least one request to receive tailored matches from Osmos`}</p>
                            <button className={`block btn-block-new desktop w-flex mt-2 row nowrap`} onClick={() => {
                                    context.setAddRequestPopup(true);
                                }} disabled={!props.canAddRequest} style={{padding: 14, borderRadius: 14}}>
                                <p>{`Post a new request`}</p>
                                <div className={`btn-symbol h-3 w-3 centered`}>
                                    <img src={PlusBlack} alt={`+`}></img>
                                </div>
                            </button>
                        </div> :
                        <button className={`block btn-block-new desktop w-flex`} onClick={() => {
                                context.setAddRequestPopup(true);
                            }} disabled={!props.canAddRequest}>
                            <div className={`row nowrap mb-1`}>
                                <p>{`Post a new request`}</p>
                                <div className={`btn-symbol h-3 w-3 centered`}>
                                    <img src={PlusBlack} alt={`+`}></img>
                                </div>
                            </div>
                            <p data-size={6} className={`not-active semibold seminarrow`} data-color={`black-40-opacity`}>
                                {`Who are you looking for right now?`}
                            </p>
                        </button>}
                    </div>

                    <div className={`w-flex mb-3 row nowrap`} style={{position: `relative`}}>
                        <button className={`block btn-flex desktop circled not-high p-right row w-8 gap-2`}
                                onClick={() => { setOpenFilter(true) }}>
                            <p className={`pr`}>{`Filter requests`}</p> 
                            <img className={`not-scale w-3`} src={FunnelBlack} alt={`>`}></img>          
                        </button>

                        <button className={`block btn-flex desktop circled not-high p-right row left gap-2`} data-color={`black`}
                                onClick={() => { context.setAddRequestPopup(true) }}>
                            <p className={`pr`}>{`Add request`}</p> 
                            <img src={PlusWhite2} alt={`+`} className={`w-3`}></img>           
                        </button> 

                        <div className={`block p-3 b-3`} style={{
                            position: `absolute`, top: 0, 
                            left: globalContext.isTablet ? 0 : -24, right: globalContext.isTablet ? 0 : -24, 
                            zIndex: 1,
                            boxShadow: `0px 11px 53px 0px #00000014`,
                        }} hidden={!openFilter}>                            
                            <p data-size={8} className={`semibold seminarrow px`}>{`Filter requests`}</p>
                            <div className={`w-flex my-2 row left`}>
                                {[
                                    `Share experience`,
                                    `Find a job`,
                                    `Hire`,
                                    `Find clients`,
                                    `Raise funds`,
                                    `Invest`,
                                    `Find experts`,
                                    `Find partners`,
                                    `Network`,
                                ].map((elem, index) => {return (
                                    <button key={index} className={`block btn-circled-32 desktop row left`} data-color={`transparent`} 
                                            data-border={`${!filterByRequest.includes(index) ? `black-40-opacity` : `green`}-1`} 
                                            type={`button`} onClick={() => { 
                                                setFilterByRequest(
                                                    val => val.includes(index) ? val.filter(elem => elem !== index) : val.concat([index])) 
                                            }}>
                                        <p data-color={!filterByRequest.includes(index) ? `black-40-opacity` : `green`}>{elem}</p>
                                        {/* <div className={`centered`} style={{
                                            width: 16, height: 16, marginRight: -4, borderRadius: 8, border: `solid black 2px`,
                                        }} hidden={!filterByRequest.includes(index)}>
                                            <img src={PlusBlack} alt={`+`} className={`w-1`} style={{transform: `rotate(45deg)`}}></img>
                                        </div> */}
                                    </button>
                                )})}
                            </div>
                            <p  data-size={8} className={`semibold seminarrow px`}>{`Filter by industry`}</p>
                            <input className={`mobile w-flex mt-1 mb-2`} placeholder={`Search in 40+ industries`} data-size={6} value={filterIndustrySearch}
                                onChange={e => { setFilterIndustrySearch(e.target.value) }}></input>
                            <div className={`row left mb-2`} data-color={`light-gray`}>
                            {((
                                    Industries.filter((val: string) => val.toLowerCase().includes(filterIndustrySearch.toLocaleLowerCase().trim()))?.length &&
                                    filterIndustrySearch.trim()?.length
                                ) ?
                                Industries.filter((val: string) => val.toLowerCase().includes(filterIndustrySearch.toLocaleLowerCase().trim())) : (filterByIndustry?.length ? filterByIndustry : context.profile.industry))
                                .map((elem: string, index: number) => {return (
                                    <button className={`block btn-circled-32 desktop row`} 
                                            data-color={filterByIndustry.includes(elem) ? `transparent` : `light-gray`}
                                            data-border={filterByIndustry.includes(elem) ? `green-1` : null}
                                            onClick={() => {
                                                setFilterByIndustry((val: any) => 
                                                    val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                                )
                                                const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                                if (input) {
                                                    input.focus();
                                                }
                                            }} type={`button`} id={`select-btn`} key={index}>
                                        <p data-color={filterByIndustry.includes(elem) ? 'green' : `black-40-opacity`}>
                                            {elem}
                                        </p>
                                        {filterByIndustry.includes(elem) &&
                                        <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                                    </button>
                                )})}
                            </div>

                            <button className={`block btn-circled-40 desktop row center mt-3`} style={{width: 148}} 
                                    onClick={e => { e.stopPropagation(); setOpenFilter(false) }} data-color={`black`}>
                                <p>{`Ok`}</p>
                            </button>
                        </div>
                    </div>
                    {(filterByRequest?.length > 0 || filterByIndustry?.length > 0) && <>                    
                        <div className={`neg-mb-3 pt-2 px-2`}></div>
                        <div className={`w-flex row left pb-2`}>
                            {filterByRequest.map((elem: number, index: number) => {return (
                                <button className={`block btn-circled-40 desktop row left`} onClick={() => {
                                    setFilterByRequest(
                                        val => val.filter(elem_ => elem_ !== elem))
                                }} key={index}>
                                    <p style={{opacity: 0.6}}>{[
                                        `Share experience`,
                                        `Find a job`,
                                        `Hire`,
                                        `Find clients`,
                                        `Raise funds`,
                                        `Invest`,
                                        `Find experts`,
                                        `Find partners`,
                                        `Network`,
                                    ][elem]}</p>
                                    <img src={PlusBlack} alt={`+`} style={{opacity: 0.6, transform: `rotate(45deg)`}} className={`w-2`}></img>
                                </button>
                            )})}
                            {filterByIndustry.map((elem: string, index: number) => {return (
                                <button className={`block btn-circled-40 desktop row left`} onClick={() => {
                                    setFilterByIndustry(
                                        val => val.filter(elem_ => elem_ !== elem))
                                }} key={index}>
                                    <p style={{opacity: 0.6}}>{elem}</p>
                                    <img src={PlusBlack} alt={`+`} style={{opacity: 0.6, transform: `rotate(45deg)`}} className={`w-2`}></img>
                                </button>
                            )})}
                        </div>
                    </>}

                    {/* {!!feed?.length &&
                    <div className={`block p-3 b-3 w-flex mb-3`}>
                        <p data-size={5} className={`semibold seminarrow mb-2`}>{`Sort your feed`}</p>
                        <button className={`btn-circled-24 desktop p-left row left noscroll nowrap mb-2 gap-1`}
                                onClick={() => {
                                    // setSortOld(false);
                                }}>
                            <div className={`checkbox-radio w-3 h-3 b-2 centered mb noshrink`} data-checked={true}>
                                <div className={`checkbox-center h-1 w-1 b`}></div>
                            </div>
                            <p data-size={6}>{'New ones first'}</p> 
                        </button>
                        <button className={`btn-circled-24 desktop p-left row left noscroll nowrap gap-1`}
                                onClick={() => {
                                    // setSortOld(true);
                                }}>
                            <div className={`checkbox-radio w-3 h-3 b-2 centered mb noshrink`} data-checked={false}>
                                <div className={`checkbox-center h-1 w-1 b`}></div>
                            </div>
                            <p data-size={6}>{'Old ones first'}</p> 
                        </button>
                    </div>} */}  
                </div> 
            </div> :            
            <div className={`w-flex`}>
                <div className={`skeleton w-flex b-3 h-8 mb-3`}></div>
            </div>}

            {context.tab === 0 && 
            (!!feed && !!backFeed ? <> 
                {!feed?.length ? 
                <MessageCardDesktop profile={props.profile} type={`feed`}/> : <>                     
                {/* <div className={`block b-3 px-3 py-2 w-flex mb-3`}> */}
                {!!feed && !!backFeed && FilterFeed(feed).slice(0, 3).map((elem, index) => { return (
                <div className={`w-flex clickable mb-2`} onClick={() => { 
                        if (window.getSelection()?.type !== "Range") {
                            const newWindow: WindowProxy | null = window.open(`/request/${(elem.requests ?? [])[0]?.id}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    }} key={index}>
                    <FeedRequestCardDesktop className={`w-flex`} profile={elem} onRequested={(id: string) => {
                                                setFeed(val => val ? SetDict(val, [index, 'is_requested'], (val[index]?.is_requested ?? []).concat([id])) : val);
                                            }}
                                            desktop={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry}/>
                </div>)})}
                {/* </div>                     */}

                <div className={`block p-3 b-3 w-flex mb-3`} data-color={rndColor[0] ?? `gray`} ref={recommendScrollBlock}
                        onMouseEnter={() => {
                        if (recommendScrollElem.current && 
                            recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                            if (IsLeftScroll(recommendScrollElem.current)) {
                                setShowLeftRecommendBtn(true);
                            }
                            if (IsRightScroll(recommendScrollElem.current)) {
                                setShowRightRecommendBtn(true);
                            }
                        }
                        }} onMouseLeave={() => { 
                        setShowLeftRecommendBtn(false); 
                        setShowRightRecommendBtn(false);
                    }}>
                    <div className={`row left nowrap`}>
                        <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2] ?? `black`}>
                            {!!props.recommendationsCommon?.length ? 
                            <p data-size={6} className={`narrow px-1`}>{props.recommendationsCommon?.length}</p> :
                            <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                        </div>
                        <p data-size={5} className={`semibold seminarrow`} data-color={rndColor[2]}>{`Similar to you`}</p>
                    </div>
                    <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-3`}>
                        <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftRecommendBtn} style={{zIndex: 1}}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(-29px, 0) rotate(180deg)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft - 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>

                        <div className={`row x-auto left top noshrink nowrap w-flex px-3`} ref={recommendScrollElem}
                            onScroll={() => {
                                if (recommendScrollElem.current && 
                                    recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                    if (IsLeftScroll(recommendScrollElem.current)) {
                                        setShowLeftRecommendBtn(true);
                                    } else {
                                        setShowLeftRecommendBtn(false);
                                    }
                                    if (IsRightScroll(recommendScrollElem.current)) {
                                        setShowRightRecommendBtn(true);
                                    } else {
                                        setShowRightRecommendBtn(false);
                                    }
                                }
                            }}>
                            {props.recommendationsCommon?.length ?
                            props.recommendationsCommon.map((elem: any, index: number) => {return (
                                <SmallUserBlock profile={elem?.user} index={index} 
                                                setRecommendations={props.setRecommendationsCommon}
                                                onSubscribe={() => { 
                                                    FeedRequest(
                                                        cookie['user_id_cookie'], 
                                                        setFeed, setBackFeed, 0, 
                                                        () => { setFeedPage(1) },
                                                    )
                                                }} onClick={() => { 
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem?.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    } 
                                                }} key={index}/>
                            )}) :
                            [1, 2, 3].map((elem, index) => {return (
                                <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}>
                                    <NewAvatar size={0} className={`mb-2 circled`}/>
                                    <div style={{height: 61}}>
                                        <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                        <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                    </div>
                                    <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                </div>
                            )})}
                        </div> 

                        <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightRecommendBtn}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(29px, 0)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft + 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>
                    </div> 
                </div>
                
                {FilterFeed(feed)?.length > 3 &&
                (!!feed && !!backFeed && FilterFeed(feed).slice(3).map((elem, index) => { return (
                <div className={`w-flex clickable mb-2`} onClick={() => {  
                        if (window.getSelection()?.type !== "Range") {
                            const newWindow: WindowProxy | null = window.open(`/request/${(elem.requests ?? [])[0]?.id}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    }} key={index}>
                    <FeedRequestCardDesktop className={`w-flex`} profile={elem} onRequested={(id: string) => {
                                                setFeed(val => val ? SetDict(val, [index + 3, 'is_requested'], (val[index + 3]?.is_requested ?? []).concat([id])) : val);
                                            }}
                                            desktop={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry}/>
                </div>)}))}

                <div className={`w-flex pb-4 pt-1 row center`} id={`feed-end-element`} style={{
                        transition: `opacity 0.2 ease-in-out`,
                        opacity: 0,
                    }}>
                    <p data-size={4} className={`semibold center`} data-color={`gray`}>
                        {`You've scrolled to the end of your feed.`}<br/>
                        {`Please come back later.`}
                    </p>
                </div></>}
            </> : <div className={`skeleton w-flex b-3 h-10 mb-6`}></div>)}

            {context.tab === 1 && 
            (!!(searchData?.user_requests ?? recRequests) ? <>
                {searchData?.users?.length === 0 && lastSearch?.length !== 0 &&
                <div className={`block w-flex b-3 h-7 centered mb-3`}>
                    <p data-size={5}>{`No results found`}</p>
                </div>}

                {FilterSearchRequests(searchData?.user_requests ?? recRequests)?.length > 0 &&  
                (FilterSearchRequests(searchData?.user_requests ?? recRequests).slice(0, 3).map((elem: any, index: number) => { return (
                <div className={`w-flex clickable mb-2`} onClick={() => {  
                        if (window.getSelection()?.type !== "Range") {
                            const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    }} key={index}>
                    <FeedRequestCardDesktop className={`w-flex`} profile={elem.user} request={elem.card} onRequested={(id: string) => {
                                                setRecRequests((val: any[]) => SetDict(val, [index + 3, 'is_requested'], (val[index + 3]?.is_requested ?? []).concat([id])));
                                            }}
                                            desktop={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry} selectedTags={filterByIndustry}/>
                </div>)}))}

                <div className={`block p-3 b-3 w-flex mb-2`} data-color={rndColor[0] ?? `gray`} ref={recommendScrollBlock}
                        onMouseEnter={() => {
                        if (recommendScrollElem.current && 
                            recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                            if (IsLeftScroll(recommendScrollElem.current)) {
                                setShowLeftRecommendBtn(true);
                            }
                            if (IsRightScroll(recommendScrollElem.current)) {
                                setShowRightRecommendBtn(true);
                            }
                        }
                        }} onMouseLeave={() => { 
                        setShowLeftRecommendBtn(false); 
                        setShowRightRecommendBtn(false);
                    }} hidden={searchData?.users?.length === 0 && context.globalSearch?.length === 0}>
                    {!searchData?.users?.length &&
                    <div className={`row left nowrap mb-3`}>
                        <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2] ?? `black`}>
                            {!!props.recommendationsCommon?.length ? 
                            <p data-size={6} className={`narrow px-1`}>{props.recommendationsCommon?.length}</p> :
                            <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                        </div>
                        <p data-size={5} className={`semibold seminarrow`} data-color={rndColor[2]}>{`Similar to you`}</p>
                    </div>}
                    <div className={`row nogap nowrap min-w-flex neg-mx-3`}>
                        <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftRecommendBtn} style={{zIndex: 1}}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(-29px, 0) rotate(180deg)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft - 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>

                        <div className={`row x-auto left top noshrink nowrap w-flex px-3`} ref={recommendScrollElem}
                            onScroll={() => {
                                if (recommendScrollElem.current && 
                                    recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                    if (IsLeftScroll(recommendScrollElem.current)) {
                                        setShowLeftRecommendBtn(true);
                                    } else {
                                        setShowLeftRecommendBtn(false);
                                    }
                                    if (IsRightScroll(recommendScrollElem.current)) {
                                        setShowRightRecommendBtn(true);
                                    } else {
                                        setShowRightRecommendBtn(false);
                                    }
                                }
                            }}>
                            {(searchData?.users?.length > 0 ? searchData?.users : props.recommendationsCommon)?.length > 0 ?
                            (searchData?.users?.length > 0 ? searchData?.users : props.recommendationsCommon).map((elem: any, index: number) => {return (
                                <SmallUserBlock profile={elem?.user ?? elem} index={index} 
                                                setRecommendations={searchData?.users?.length > 0 ? () => {} : props.setRecommendationsCommon}
                                                setSearchData={searchData?.users?.length > 0 ? setSearchData : () => {}}
                                                onSubscribe={() => { 
                                                    FeedRequest(
                                                        cookie['user_id_cookie'], 
                                                        setFeed, setBackFeed, 0, 
                                                        () => { setFeedPage(1) },
                                                    )
                                                }} onClick={() => { 
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${(elem?.user ?? elem)?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    } 
                                                }} key={index}/>
                            )}) :
                            [1, 2, 3].map((elem, index) => {return (
                                <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}>
                                    <NewAvatar size={0} className={`mb-2 circled`}/>
                                    <div style={{height: 61}}>
                                        <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                        <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                    </div>
                                    <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                </div>
                            )})}
                        </div> 

                        <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightRecommendBtn}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(29px, 0)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft + 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>
                    </div> 
                </div>
                
                {FilterSearchRequests(searchData?.user_requests ?? recRequests)?.length > 3 &&  
                (FilterSearchRequests(searchData?.user_requests ?? recRequests).map((elem: any, index: number) => { return (index < 3 ? null :
                <div className={`w-flex clickable mb-2`} onClick={() => { 
                        if (window.getSelection()?.type !== "Range") {
                            const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    }} key={index}>
                    <FeedRequestCardDesktop className={`w-flex`} profile={elem.user} request={elem.card} onRequested={(id: string) => {
                                                setRecRequests((val: any[]) => SetDict(val, [index + 3, 'is_requested'], (val[index + 3]?.is_requested ?? []).concat([id])));
                                            }}
                                            desktop={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry} selectedTags={filterByIndustry}/>
                </div>)}))}

                <div className={`w-flex pb-4 pt-1 row center`} id={`people-end-element`} style={{
                        transition: `opacity 0.2 ease-in-out`,
                        // opacity: 0,
                    }}>
                    <p data-size={4} className={`center semibold`} data-color={`gray`}>
                        {`You've scrolled to the end of your feed.`}<br/>
                        {`Please come back later.`}
                    </p>
                </div>
            </> : <>
                <div className={`skeleton w-flex b-3 mb-3 h-9`}></div>
                <div className={`skeleton w-flex b-3 h-10 mb-6`}></div>
            </>)}

            {context.tab === Infinity && 
            (!!feed && !!backFeed ? <>
                {!!recRequests?.length &&
                <div className={`block b-3 px-3 py-2 w-flex mb-3`} data-with-hint-={searchParams.get(`first_login`) === `true`} id={`feed-block-hint`}>  
                    {!!recRequests && recRequests.slice(0, 3).map((elem: any, index: number) => { return (
                    <div className={`w-flex clickable mb-2`} onClick={() => { 
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }} key={index}>
                        {!!index && 
                        <div className={`w-flex block`} data-color={`gray`} style={{height: 1, marginTop: -1}}></div>}
                        <FeedRequestCardDesktop className={`w-flex`} profile={elem.user} request={elem.card}
                                                desktop={true} onlyValid={true} index={index} onRequested={(id: string) => {
                                                    setRecRequests((val: any[]) => SetDict(val, [index, 'is_requested'], (val[index]?.is_requested ?? []).concat([id])));
                                                }}
                                                selectBtn={!index && (!(props.profile?.first_login ?? [`feed`]).includes(`feed`) || searchParams.get(`first_login`) === `true-`)}/>
                    </div>)})}
                </div>}

                <div className={`block p-3 b-3 w-flex mb-3`} data-color={rndColor[0] ?? `gray`} ref={recommendScrollBlock}
                        onMouseEnter={() => {
                        if (recommendScrollElem.current && 
                            recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                            if (IsLeftScroll(recommendScrollElem.current)) {
                                setShowLeftRecommendBtn(true);
                            }
                            if (IsRightScroll(recommendScrollElem.current)) {
                                setShowRightRecommendBtn(true);
                            }
                        }
                        }} onMouseLeave={() => { 
                        setShowLeftRecommendBtn(false); 
                        setShowRightRecommendBtn(false);
                    }}>
                    <div className={`row left nowrap`}>
                        <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2] ?? `black`}>
                            {!!props.recommendationsCommon?.length ? 
                            <p data-size={6} className={`narrow px-1`}>{props.recommendationsCommon?.length}</p> :
                            <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                        </div>
                        <p data-size={5} className={`semibold seminarrow`} data-color={rndColor[2]}>{`Similar to you`}</p>
                    </div>
                    <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-3`}>
                        <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftRecommendBtn} style={{zIndex: 1}}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(-29px, 0) rotate(180deg)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft - 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>

                        <div className={`row x-auto left top noshrink nowrap nogap w-flex px-3`} ref={recommendScrollElem}
                            onScroll={() => {
                                if (recommendScrollElem.current && 
                                    recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                    if (IsLeftScroll(recommendScrollElem.current)) {
                                        setShowLeftRecommendBtn(true);
                                    } else {
                                        setShowLeftRecommendBtn(false);
                                    }
                                    if (IsRightScroll(recommendScrollElem.current)) {
                                        setShowRightRecommendBtn(true);
                                    } else {
                                        setShowRightRecommendBtn(false);
                                    }
                                }
                            }}>
                            {props.recommendationsCommon?.length ?
                            props.recommendationsCommon.map((elem: any, index: number) => {return (
                                <SmallUserBlock profile={elem?.user} index={index} className={`${!!index ? `ml-2` : ``}`} 
                                                setRecommendations={props.setRecommendationsCommon}
                                                onSubscribe={() => { 
                                                    FeedRequest(
                                                        cookie['user_id_cookie'], 
                                                        setFeed, setBackFeed, 0, 
                                                        () => { setFeedPage(1) },
                                                    )
                                                }} onClick={() => { 
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem?.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    } 
                                                }} key={index}/>
                            )}) :
                            [1, 2, 3].map((elem, index) => {return (
                                <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}>
                                    <NewAvatar size={0} className={`mb-2 circled`}/>
                                    <div style={{height: 61}}>
                                        <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                        <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                    </div>
                                    <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                </div>
                            )})}
                        </div> 

                        <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightRecommendBtn}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(29px, 0)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft + 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>
                    </div> 
                </div>
                
                {recRequests?.length > 3 &&
                <div className={`block b-3 px-3 py-2 w-flex mb-3`}>    
                    {!!recRequests && recRequests.slice(3).map((elem: any, index: number) => { return (
                    <div className={`w-flex clickable mb-2`} onClick={() => { 
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }} key={index}>
                        {!!index && 
                        <div className={`w-flex block`} data-color={`gray`} style={{height: 1, marginTop: -1}}></div>}
                        <FeedRequestCardDesktop className={`w-flex`} profile={elem.user} request={elem.card} onRequested={(id: string) => {
                                                    setRecRequests((val: any[]) => SetDict(val, [index + 3, 'is_requested'], (val[index + 3]?.is_requested ?? []).concat([id])));
                                                }}
                                                desktop={true} onlyValid={true} index={index}/>
                    </div>)})}
                </div>}

                <div className={`w-flex pb-4 pt-1 row center`} id={`people-end-element`} style={{
                        transition: `opacity 0.2 ease-in-out`,
                        // opacity: 0,
                    }}>
                    <p data-size={4} className={`center semibold`} data-color={`gray`}>
                        {`You've scrolled to the end of your feed.`}<br/>
                        {`Please come back later.`}
                    </p>
                </div>
            </> : <>
                <div className={`skeleton w-flex b-3 mb-3 h-9`}></div>
                <div className={`skeleton w-flex b-3 h-10`}></div>
            </>)}
            </div>
        </div>
    )
}

export const ProfileRequestsDesktop = (props: any) => {
    const [ searchParams ] = useSearchParams();
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);
    const [ cookie ] = useCookies([`user_id_cookie`]);
    
    const [ requestNumber, setRequestNumber ] = useState<number>(0);
    const [ recommendations, setRecommendations ] = useState<any[]>([]);
    const [ recommendationsAbortController, setRecommendationsAbortController ] = useState<AbortController>(
        new AbortController()
    );

    const [ rndColor, setRndColor ] = useState<string[]>([]);    
    const [ showDeleteRequestPopup, setShowDeleteRequestPopup ] = useState<string | null>(null);
    const [ editRequestPopup, setEditRequestPopup ] = useState<any | null>(null);
    const [ showRelevanceSurvey, setShowRelevanceSurvey ] = useState<boolean>(false);
    const [ relevanceOption, setRelevanceOption] = useState<number>();const [ recommendationsLoadingText, setRecommendationsLoadingText ] = useState<number>(0);
    const [ recommendationsUserNumber, setRecommendationsUserNumber ] = useState<number>(0);
    const texts: string[] = [
        `Analyzing your preferences`,
        `Scanning our database for suitable matches`,
        `Compiling a personalized list based on your criteria`,
        `Reviewing potential matches for your request`,
        `Finalizing your customized match list`,
    ]    
    const relevanceOptions = ["Not enough info in profile", "Does not match my request", "Other"];
    const [ relevanceDescription, setRelevanceDescription] = useState<string>('');
    const [ textsTimer, setTextsTimer ] = useState<NodeJS.Timeout>();
        
    const scrollRecommendationsBlock = useRef<HTMLDivElement | null>(null);

    const recommendScrollBlock = useRef<HTMLDivElement | null>(null);
    const recommendScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftRecommendBtn, setShowLeftRecommendBtn ] = useState<boolean>(false);
    const [ showRightRecommendBtn, setShowRightRecommendBtn ] = useState<boolean>(false);

    const requestsScrollBlock = useRef<HTMLDivElement | null>(null);
    const requestsScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftRequestsBtn, setShowLeftRequestsBtn ] = useState<boolean>(false);
    const [ showRightRequestsBtn, setShowRightRequestsBtn ] = useState<boolean>(false);

    const projectScrollBlock = useRef<HTMLDivElement | null>(null);
    const projectScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftProjectBtn, setShowLeftProjectBtn ] = useState<boolean>(false);
    const [ showRightProjectBtn, setShowRightProjectBtn ] = useState<boolean>(false);

    const GetRecommendations = () => {
        return JSON.parse(sessionStorage.getItem('store_recommendations') ?? '{}');
    }

    const SetRecommendations = (id: string, recommendations: any) => {
        const currentRec = JSON.parse(sessionStorage.getItem('store_recommendations') ?? '{}');
        sessionStorage.setItem('store_recommendations', JSON.stringify(SetDict(currentRec, [id], recommendations)))
    }

    const SetRecommendationsAgain = (id: string, text: string, setInfo: Function) => {
        setInfo([]);
        recommendationsAbortController.abort();
        const newAbortController = new AbortController();
        setRecommendationsAbortController(newAbortController);
        CardRecommendation(
            id, 
            text,
            setInfo,
            {}, newAbortController.signal,                
        )
    }

    const UpdateRecommendations = (profile: any) => {
        if (profile?.requests[requestNumber]?.answers[0]) {
            if (GetRecommendations()[profile?.requests[requestNumber]?.id]?.length === 10) {
                setRecommendations(GetRecommendations()[profile?.requests[requestNumber]?.id])
            } else {
                SetRecommendationsAgain(
                    cookie[`user_id_cookie`],
                    profile?.requests[requestNumber]?.answers[0],
                    setRecommendations,
                )
            }
            setRndColor(ScreenColors[[0, 1, 2, 2, 1, 2, 1, 2, 0, 1, 0, 2, 1, 2][requestNumber % 14]]);
        } else if (profile?.requests?.length === 0) {
            if (profile?.about) {
                SetRecommendationsAgain(
                    cookie[`user_id_cookie`], 
                    profile?.about,
                    setRecommendations,
                )
                setRndColor(ScreenColors[0]);
            } else if (profile?.industry) {
                SetRecommendationsAgain(
                    cookie[`user_id_cookie`], 
                    profile?.industry,
                    setRecommendations,
                )
                setRndColor(ScreenColors[0]);
            }
        }
    }

    const onResizeRecommendations = () => {  
        setRecommendationsUserNumber(0);
    }

    useEffect(() => {
        UpdateRecommendations(context.profile);
    }, [(context.profile?.requests ?? [])[requestNumber], context.profile?.requests])

    useEffect(() => {
        if (rndColor) {
            const btn = document.querySelector(`#footer-button-color`) as HTMLButtonElement;
            // console.warn(`main changed`)
            if (btn) {
                btn.setAttribute(`footer-color`, rndColor[0] ?? ``);
                btn.click();
            }
        }
    }, [rndColor])

    useEffect(() => {
        if (props.loaded) {
            setRecommendationsLoadingText(0);
            setRecommendationsUserNumber(0);
            if (!!textsTimer) {
                clearInterval(textsTimer);
            }            
            setTextsTimer(setInterval(() => {
                setRecommendationsLoadingText((val: number) => Math.min(val + 1, 4))
            }, 1100))
        } else if (!!textsTimer) {
            clearInterval(textsTimer);
            setRecommendationsLoadingText(-1);
        } 
    }, [props.loaded, (context.profile?.requests ?? [])[requestNumber], context.profile?.requests])

    useEffect(() => {
        setShowRelevanceSurvey(false);
        setRelevanceDescription('');
        setRelevanceOption(undefined);

        if (recommendations?.length > 0) {
            SetRecommendations(
                context.profile?.requests[requestNumber]?.id,
                recommendations,
            )
        }

        // const aboutReadMoreElem = document.querySelectorAll(`#read-more-about`);
        document.querySelectorAll(`#read-more-about`).forEach((elem, index: number) => {
            const Elem = elem as HTMLDivElement
            setRecommendations(val => SetDict(
                val, [Number(Elem.getAttribute('data-index')), "user", "openAbout"], 
                79 > Elem?.scrollHeight
            ))
        });

        (recommendations[recommendationsUserNumber]?.user?.requests ?? []).forEach((elem: any, index: number) => {
            document.querySelectorAll(`#read-more-requests-${index}`).forEach((elem) => {
                const Elem = elem as HTMLDivElement;
                setRecommendations(val => SetDict(
                    val, [Number(Elem.getAttribute('data-index')), "user", "requests", index, "openRequest"], 
                    68 > Elem?.scrollHeight
                ))
            })
        });

        if (scrollRecommendationsBlock?.current) {
            scrollRecommendationsBlock.current.scrollTo({
                left: (scrollRecommendationsBlock.current.offsetWidth - 2 * 58 + 24) * recommendationsUserNumber,
                behavior: `smooth`
            })
        }
    }, [recommendations?.length, recommendationsUserNumber])

    useEffect(() => {
        if (scrollRecommendationsBlock?.current) {
            scrollRecommendationsBlock.current.scrollTo({
                left: (scrollRecommendationsBlock.current.offsetWidth - 2 * 58 + 24) * recommendationsUserNumber,
                behavior: `smooth`
            })
        }
    }, [showRelevanceSurvey])

    useEffect(() => {
        window.addEventListener('resize', onResizeRecommendations);

        return () => {
            window.removeEventListener('resize', onResizeRecommendations)
        }
    }, [])

    return (
        <div className={`w-flex column top`}>
            <div className={`row w-flex md-w-10 sm-w-flex gap-3 grid-1-2 md-grid-1 top mb-5 px-6 md-px-0`}>
                <div style={{top: 82}} data-md-sticky-={searchParams.get(`first_login`) === `true` ? null : false}>
                    {props.loaded ? <>
                    <div className={`w-flex`} data-md-hidden={true}>
                        <div className={`block px-3 pb-3 b-3 w-flex ${context.profile?.requests?.length === 0 ? `` : `h-chat`} mb-3 y-auto`} ref={requestsScrollBlock}
                                data-color={context.profile?.requests?.length === 0 ? `extra-light-coral` : undefined}
                                data-border={context.profile?.requests?.length === 0 ? `coral-2` : undefined}>   
                            <div className={`row w-flex mt-3`} hidden={context.profile?.requests?.length === 0}>
                                <div className={`row left nowrap`}>
                                    <div className={`block min-w-3 h-3 centered b-2`} data-color={`black`} hidden={!context.profile?.requests?.length}>
                                        <p data-size={6} className={`px-1`}>{context.profile?.requests?.length}</p> 
                                    </div>
                                    <p data-size={5} className={`semibold seminarrow`}>{`My requests`}</p>
                                </div>
                                <button className={`btn-symbol h-3 w-3 centered`} hidden={context.profile?.requests?.length < 2}
                                        onClick={() => { context.setAddRequestPopup(true) }}>
                                    <img src={PlusBlack} alt={`+`}></img>
                                </button>
                            </div>

                            {sessionStorage.getItem(`first_login_requests`) === `true` && context.profile?.requests?.length > 0 && <>
                            <div className={`block b-2 p-2 mb mt-2`} data-color={`light-green`} style={{marginLeft: -10, marginRight: -10, width: `calc(100% + 20px)`}}>
                                <p data-size={6} data-color={`dark-green`}>
                                    {`When updating requests or modifying current ones, Osmos delivers a stream of relevant professionals.`}
                                </p>    
                            </div>
                            <div className={`block b-2 p-2`} data-color={`light-green`} style={{marginLeft: -10, marginRight: -10, width: `calc(100% + 20px)`}}>
                                <p data-size={6} data-color={`dark-green`}>
                                    {`Key: Stay sharp, focused, and industry-specific. Showcase how assisting you holds relevance for your audience.`}
                                </p>    
                            </div>
                            </>}

                            <div className={`neg-mt-2 h-3`}></div>

                            <div className={`block p-3 b-2 clickable w-flex mt-2 centered`} 
                                    hidden={!(context.profile?.requests?.length < 2)} style={{
                                        position: `relative`, height: 235,
                                    }} data-color={context.profile?.requests?.length === 0 ? undefined : `light-gray`} onClick={() => { context.setAddRequestPopup(true) }}>
                                <div data-border={`transparent-4`} style={{
                                    borderRadius: 19,
                                    position: `absolute`, top: -4, left: -4, bottom: -4, right: -4,
                                }} className={`block row bottom right gap-0 p-2 `} data-color={`transparent`}
                                    onMouseEnter={() => {
                                        const editBlock = document.querySelector(`#request-add-block-left`) as HTMLDivElement;
                                        if (editBlock) {
                                            editBlock.setAttribute(`data-color`, `black-5-opacity`);
                                        }
                                    }} onMouseLeave={() => {
                                        const editBlock = document.querySelector(`#request-add-block-left`) as HTMLDivElement;
                                        if (editBlock) {
                                            editBlock.setAttribute(`data-color`, `transparent`);
                                        } 
                                    }} id={`request-add-block-left`}>
                                </div>
                                <div className={`column top`}>
                                    <div className={`btn-symbol h-3 w-3 centered mb-1`}>
                                        <img src={PlusBlack} alt={`+`}></img>
                                    </div>
                                    <p data-size={6} className={`center px-3 semibold`} data-color={`black-40-opacity`}>
                                        {context.profile?.requests?.length === 0 ? `Post at least one request to receive tailored matches from Osmos` : `Post a new request`}
                                    </p>
                                </div>
                            </div>

                            {!!context.profile?.requests?.length &&
                            context.profile?.requests.map((elem: any, index: number) => {return (
                                <div className={`block p-2 b-2 clickable w-flex mt-2 column left h-flex`} key={index} style={{
                                            position: `relative`, height: 235,
                                        }} onClick={() => { setRequestNumber(index) }}>
                                    <div data-border={index === requestNumber ? `black-20-opacity-4` : `white-4`} style={{
                                        borderRadius: 19,
                                        opacity: typeof elem?.style?.background === `number` ? RequestColors2[elem?.style?.background]?.bgOpacity : 1,
                                        position: `absolute`, top: -4, left: -4, bottom: -4, right: -4,
                                    }} className={`block row bottom right gap-0 p-2 `}
                                        onMouseEnter={() => {
                                            const editBlock = document.querySelector(`#request-edit-block-${elem.id}-left`) as HTMLDivElement;
                                            const editBtn = document.querySelector(`#request-edit-button-${elem.id}-left`) as HTMLButtonElement;
                                            const deleteBtn = document.querySelector(`#request-delete-button-${elem.id}-left`) as HTMLButtonElement;
                                            if (editBlock) {
                                                editBlock.setAttribute(`data-color`, `black-5-opacity`);
                                            }
                                            if (editBtn) {
                                                editBtn.hidden = false;
                                            }
                                            if (deleteBtn) {
                                                deleteBtn.hidden = context.profile?.requests?.length < 2;
                                            }
                                        }} onMouseLeave={() => {
                                            const editBlock = document.querySelector(`#request-edit-block-${elem.id}-left`) as HTMLDivElement;
                                            const editBtn = document.querySelector(`#request-edit-button-${elem.id}-left`) as HTMLButtonElement;
                                            const deleteBtn = document.querySelector(`#request-delete-button-${elem.id}-left`) as HTMLButtonElement;
                                            if (editBlock) {
                                                editBlock.setAttribute(`data-color`, `transparent`);
                                            } 
                                            if (editBtn) {
                                                editBtn.hidden = true;
                                            }
                                            if (deleteBtn) {
                                                deleteBtn.hidden = true;
                                            }
                                        }} data-color={typeof elem?.style?.background === `number` ? RequestColors2[elem?.style?.background]?.color : `light-gray`}>
                                        {/* <button className={`block btn centered b px-1`} data-color={`white`} type={`submit`}
                                                style={{height: 40}}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setEditRequestPopup(elem);
                                                }} hidden={true} id={`request-edit-button-${elem.id}-left`}>
                                            <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                                        </button>
                                        <button className={`block btn centered b px-1`} data-color={`white`} type={`submit`} 
                                                style={{height: 40}}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setShowDeleteRequestPopup(elem.id);
                                                }} hidden={true} id={`request-delete-button-${elem.id}-left`}>
                                            <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                                        </button> */}
                                    </div>
                                    <div className={`w-flex`} style={{position: `relative`, zIndex: 1}}>
                                        <p data-size={6} data-color={`black-40-opacity`}>{DateFromString(elem?.created_at)}</p>
                                        <p data-size={6} data-color={`black`} className={`my-1`} data-lines={6}>
                                            {(elem.answers[0] ? elem.answers[0] : `Add description for your request...`).trim()
                                            .split(`\n`).map((elem_: string, index_: number) => {return (<Fragment key={index_}>
                                                <span className={`article`}>{elem_}</span>
                                            </Fragment>)})}
                                        </p>
                                    </div>
                                    {/* {!!getRec(elem?.id) && index !== requestNumber &&
                                    <div className={`block label btn-rectangle-40 desktop p-right w-flex row nowrap`}>
                                        <div className={`row left nogap nowrap`}>
                                            {getRec(elem?.id).map((elem_: any, index_: number) => {return (
                                                <NewAvatar name={elem_?.name} avatar={elem_?.avatar?.link} type={elem_?.avatar?.style}
                                                        size={4} className={`neg-ml-2 neg-my-2`} key={index_}/>
                                            )})}
                                            <p data-size={6} className={`semibold seminarrow ml`}>{`And 7 more`}</p>
                                        </div>
                                        <div className={`btn-symbol w-3 h-3 centered`}>
                                            <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                                        </div>
                                    </div>} */}
                                    <div className={`row nowrap w-flex`} style={{position: `relative`, zIndex: 1}}>
                                        <button className={`block btn centered b px-1`} data-color={`white`} type={`button`} 
                                                style={{height: 40}}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    const newWindow: WindowProxy | null = window.open(`/request/${elem?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    }
                                                }}>
                                            <img src={EyeBlack} alt={``} className={`w-3 h-3`}></img>
                                        </button>
                                        <div className={`row left nowrap`}>
                                            <button className={`block btn centered b px-1`} data-color={`white`} type={`button`}
                                                    style={{height: 40}}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setEditRequestPopup(elem);
                                                    }}>
                                                <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                                            </button>
                                            <button className={`block btn centered b px-1`} data-color={`white`} type={`button`} 
                                                    style={{height: 40}}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setShowDeleteRequestPopup(elem.id);
                                                    }} hidden={context.profile?.requests?.length < 2}>
                                                <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )})}
                        </div>
                    </div>
                    
                    <div className={`block p-3 b-3 w-flex`} ref={requestsScrollBlock} 
                        data-hidden={true} data-md-shown={true}
                        data-color={context.profile?.requests?.length === 0 ? `extra-light-coral` : undefined}>   
                        <div className={`row w-flex mb-3`}>
                            <div className={`row left nowrap`}>
                                <div className={`block min-w-3 h-3 centered b-2`} data-color={`black`} hidden={!context.profile?.requests?.length}>
                                    <p data-size={6} className={`px-1`}>{context.profile?.requests?.length}</p> 
                                </div>
                                <p data-size={5} className={`semibold seminarrow`}>{`My requests`}</p>
                            </div>                                
                            <button className={`btn-symbol h-3 w-3 centered`} hidden={context.profile?.requests?.length < 2}
                                    onClick={() => { context.setAddRequestPopup(true) }}>
                                <img src={PlusBlack} alt={`+`}></img>
                            </button>
                        </div>
                        

                        {sessionStorage.getItem(`first_login_requests`) === `true` && context.profile?.requests?.length > 0 && <>
                        <div className={`block b-2 p-2 w-flex mb mt-2`} data-color={`light-green`}>
                            <p data-size={6} data-color={`dark-green`}>
                                {`When updating requests or modifying current ones, Osmos delivers a stream of relevant professionals.`}
                            </p>    
                        </div>
                        <div className={`block b-2 p-2 w-flex mb-3`} data-color={`light-green`}>
                            <p data-size={6} data-color={`dark-green`}>
                                {`Key: Stay sharp, focused, and industry-specific. Showcase how assisting you holds relevance for your audience.`}
                            </p>    
                        </div>
                        </>}

                        <div className={`row nogap nowrap min-w-flex neg-mx-3`} 
                            onMouseEnter={() => {
                                if (requestsScrollElem.current && 
                                    requestsScrollElem.current.scrollWidth > requestsScrollElem.current.clientWidth) { 
                                    if (IsLeftScroll(requestsScrollElem.current)) {
                                        setShowLeftRequestsBtn(true);
                                    }
                                    if (IsRightScroll(requestsScrollElem.current)) {
                                        setShowRightRequestsBtn(true);
                                    }
                                }
                            }} onMouseLeave={() => { 
                                setShowLeftRequestsBtn(false); 
                                setShowRightRequestsBtn(false);
                            }}>
                            <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftRequestsBtn} style={{zIndex: 1}}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(-29px, 0) rotate(180deg)'
                                }} data-color={'white'} onClick={() => {
                                    if (requestsScrollElem.current) {
                                        requestsScrollElem.current.scrollTo({
                                            left: requestsScrollElem.current.scrollLeft - 294, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>

                            <div className={`row gap-2 x-auto left top noshrink nowrap nogap w-flex px-3 neg-my-2 py-2`} ref={requestsScrollElem}
                                onScroll={() => {
                                    if (requestsScrollElem.current && 
                                        requestsScrollElem.current.scrollWidth > requestsScrollElem.current.clientWidth) { 
                                        if (IsLeftScroll(requestsScrollElem.current)) {
                                            setShowLeftRequestsBtn(true);
                                        } else {
                                            setShowLeftRequestsBtn(false);
                                        }
                                        if (IsRightScroll(requestsScrollElem.current)) {
                                            setShowRightRequestsBtn(true);
                                        } else {
                                            setShowRightRequestsBtn(false);
                                        }
                                    }
                                }}>
                                
                                <div className={`block p-3 b-2 clickable centered`} hidden={!(context.profile?.requests?.length < 2)} style={{
                                        position: `relative`, width: 280, height: 235,
                                    }} data-color={context.profile?.requests?.length === 0 ? undefined : `light-gray`} 
                                    onClick={() => { context.setAddRequestPopup(true) }} data-border={context.profile?.requests?.length === 0 ? `coral-2` : undefined}>
                                    <div data-border={`transparent-4`} style={{
                                        borderRadius: 19,
                                        position: `absolute`, top: -4, left: -4, bottom: -4, right: -4,
                                    }} className={`block row bottom right gap-0 p-2 `} data-color={`transparent`}
                                        onMouseEnter={() => {
                                            const editBlock = document.querySelector(`#request-add-block`) as HTMLDivElement;
                                            if (editBlock) {
                                                editBlock.setAttribute(`data-color`, `black-5-opacity`);
                                            }
                                        }} onMouseLeave={() => {
                                            const editBlock = document.querySelector(`#request-add-block`) as HTMLDivElement;
                                            if (editBlock) {
                                                editBlock.setAttribute(`data-color`, `transparent`);
                                            } 
                                        }} id={`request-add-block`}>
                                    </div>
                                    <div className={`column top`}>
                                        <div className={`btn-symbol h-3 w-3 centered mb-1`}>
                                            <img src={PlusBlack} alt={`+`}></img>
                                        </div>
                                        <p data-size={6} className={`center px-3 semibold`} data-color={`black-40-opacity`}>
                                            {context.profile?.requests?.length === 0 ? `Post at least one request to receive tailored matches from Osmos` : `Post a new request`}
                                        </p>
                                    </div>
                                </div>
                                {(context.profile?.requests ?? []).map((elem: any, index: number) => {return (
                                    <div className={`block p-2 b-2 clickable column left h-flex`} key={index} style={{
                                                position: `relative`, width: 280, height: 235,
                                            }} onClick={() => { setRequestNumber(index) }} data-color={index !== requestNumber ? `light-gray` : null}>
                                        <div data-border={index === requestNumber ? `black-20-opacity-4` : `white-4`} style={{
                                            borderRadius: 19, 
                                            opacity: typeof elem?.style?.background === `number` ? RequestColors2[elem?.style?.background]?.bgOpacity : 1,
                                            position: `absolute`, top: -4, left: -4, bottom: -4, right: -4,
                                        }} className={`block row bottom right gap-0 p-2 `}
                                            onMouseEnter={() => {
                                                const editBlock = document.querySelector(`#request-edit-block-${elem.id}`) as HTMLDivElement;
                                                const editBtn = document.querySelector(`#request-edit-button-${elem.id}`) as HTMLButtonElement;
                                                const deleteBtn = document.querySelector(`#request-delete-button-${elem.id}`) as HTMLButtonElement;
                                                if (editBlock) {
                                                    editBlock.setAttribute(`data-color`, `black-5-opacity`);
                                                }
                                                if (editBtn) {
                                                    editBtn.hidden = false;
                                                }
                                                if (deleteBtn) {
                                                    deleteBtn.hidden = context.profile?.requests?.length < 2;
                                                }
                                            }} onMouseLeave={() => {
                                                const editBlock = document.querySelector(`#request-edit-block-${elem.id}`) as HTMLDivElement;
                                                const editBtn = document.querySelector(`#request-edit-button-${elem.id}`) as HTMLButtonElement;
                                                const deleteBtn = document.querySelector(`#request-delete-button-${elem.id}`) as HTMLButtonElement;
                                                if (editBlock) {
                                                    editBlock.setAttribute(`data-color`, `transparent`);
                                                } 
                                                if (editBtn) {
                                                    editBtn.hidden = true;
                                                }
                                                if (deleteBtn) {
                                                    deleteBtn.hidden = true;
                                                }
                                            }} data-color={typeof elem?.style?.background === `number` ? RequestColors2[elem?.style?.background]?.color : `light-gray`}>
                                            {/* <button className={`block btn centered b px-1`} data-color={`white`} type={`submit`} 
                                                    style={{height: 40}}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setEditRequestPopup(elem);
                                                    }} hidden={true} id={`request-edit-button-${elem.id}`}>
                                                <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                                            </button>
                                            <button className={`block btn centered b px-1`} data-color={`white`} type={`submit`} 
                                                    style={{height: 40}}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setShowDeleteRequestPopup(elem.id);
                                                    }} hidden={true} id={`request-delete-button-${elem.id}`}>
                                                <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                                            </button> */}
                                        </div>
                                        <div className={`w-flex`} style={{position: `relative`, zIndex: 1}}>
                                            <p data-size={6} data-color={`black-40-opacity`}>{DateFromString(elem?.created_at)}</p>
                                            <p data-size={6} data-color={`black`} className={`my-1`} data-lines={6}>
                                                {(elem.answers[0] ? elem.answers[0] : `Add description for your request...`).trim()
                                                .split(`\n`).map((elem_: string, index: number) => {return (<Fragment key={index}>
                                                    <span className={`article`}>{elem_}</span>
                                                </Fragment>)})}
                                            </p>
                                        </div>
                                        {/* {!!getRec(elem?.id) && index !== requestNumber &&
                                        <div className={`block label btn-rectangle-40 desktop p-right w-flex row nowrap`}>
                                            <div className={`row left nogap nowrap`}>
                                                {getRec(elem?.id).map((elem_: any, index_: number) => {return (
                                                    <NewAvatar name={elem_?.name} avatar={elem_?.avatar?.link} type={elem_?.avatar?.style}
                                                            size={4} className={`neg-ml-2 neg-my-2`} key={index_}/>
                                                )})}
                                                <p data-size={6} className={`semibold seminarrow ml`}>{`And 7 more`}</p>
                                            </div>
                                            <div className={`btn-symbol w-3 h-3 centered`}>
                                                <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                                            </div>
                                        </div>} */}
                                        <div className={`row nowrap w-flex`} style={{position: `relative`, zIndex: 1}}>
                                            <button className={`block btn centered b px-1`} data-color={`white`} type={`button`} 
                                                    style={{height: 40}}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        const newWindow: WindowProxy | null = window.open(`/request/${elem?.id}`, '_blank');
                                                        if (newWindow) {
                                                            newWindow.focus();
                                                        }
                                                    }}>
                                                <img src={EyeBlack} alt={``} className={`w-3 h-3`}></img>
                                            </button>
                                            <div className={`row left nowrap`}>
                                                <button className={`block btn centered b px-1`} data-color={`white`} type={`button`}
                                                        style={{height: 40}}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            setEditRequestPopup(elem);
                                                        }}>
                                                    <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                                                </button>
                                                <button className={`block btn centered b px-1`} data-color={`white`} type={`button`} 
                                                        style={{height: 40}}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            setShowDeleteRequestPopup(elem.id);
                                                        }} hidden={context.profile?.requests?.length < 2}>
                                                    <img src={TrashCoral} alt={``} className={`w-3 h-3`}></img>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )})}
                            </div> 

                            <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightRequestsBtn}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(29px, 0)'
                                }} data-color={'white'} onClick={() => {
                                    if (requestsScrollElem.current) {
                                        requestsScrollElem.current.scrollTo({
                                            left: requestsScrollElem.current.scrollLeft + 294, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>
                        </div> 
                    </div>
                    </> : <div className={`skeleton w-flex b-3 md-h-8 h-chat mb-3`}></div>}
                </div>

                <div>
                    {props.loaded ? 
                    (context.profile?.requests?.length === 0 ? 
                    <div className={`block p-3 b-3 w-flex mb-3`} ref={recommendScrollBlock}
                            onMouseEnter={() => {
                            if (recommendScrollElem.current && 
                                recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                if (IsLeftScroll(recommendScrollElem.current)) {
                                    setShowLeftRecommendBtn(true);
                                }
                                if (IsRightScroll(recommendScrollElem.current)) {
                                    setShowRightRecommendBtn(true);
                                }
                            }
                            }} onMouseLeave={() => { 
                            setShowLeftRecommendBtn(false); 
                            setShowRightRecommendBtn(false);
                        }}>
                        <div className={`row left nowrap`}>
                            <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2]}>
                                {!!recommendations?.length ? 
                                <p data-size={6} className={`narrow px-1`}>{recommendations?.length}</p> :
                                <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                            </div>
                            <p data-size={5} className={`seminarrow semibold`}>
                                {`People that may be interesting for you`}
                            </p>
                        </div>
                        <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-3`}>
                            <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftRecommendBtn} style={{zIndex: 1}}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(-29px, 0) rotate(180deg)'
                                }} data-color={'white'} onClick={() => {
                                    if (recommendScrollElem.current) {
                                        recommendScrollElem.current.scrollTo({
                                            left: recommendScrollElem.current.scrollLeft - 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>

                            <div className={`row x-auto left top noshrink nowrap nogap w-flex px-3`} ref={recommendScrollElem}
                                onScroll={() => {
                                    if (recommendScrollElem.current && 
                                        recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                        if (IsLeftScroll(recommendScrollElem.current)) {
                                            setShowLeftRecommendBtn(true);
                                        } else {
                                            setShowLeftRecommendBtn(false);
                                        }
                                        if (IsRightScroll(recommendScrollElem.current)) {
                                            setShowRightRecommendBtn(true);
                                        } else {
                                            setShowRightRecommendBtn(false);
                                        }
                                    }
                                }}>
                                {recommendations?.length ?
                                recommendations.map((elem: any, index: number) => {return (
                                    <SmallUserBlock profile={elem?.user} index={index} className={`${!!index ? `ml-2` : ``}`} 
                                                    setRecommendations={setRecommendations} key={index}
                                                    color={`light-gray`}/>
                                )}) :
                                [1, 2, 3].map((elem, index) => {return (
                                    <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}
                                            data-color={`light-gray`}>
                                        <NewAvatar size={0} className={`mb-2 circled`}/>
                                        <div style={{height: 61}}>
                                            <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                            <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                        </div>
                                        <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                    </div>
                                )})}
                            </div> 

                            <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightRecommendBtn}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(29px, 0)'
                                }} data-color={'white'} onClick={() => {
                                    if (recommendScrollElem.current) {
                                        recommendScrollElem.current.scrollTo({
                                            left: recommendScrollElem.current.scrollLeft + 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>
                        </div> 
                    </div> :
                    <div className={`block b-3 w-flex mb-3 h-chat`} ref={recommendScrollBlock}>
                        {(recommendations?.length > 0) ? 
                        <div className={`block w-flex h-flex b-3 p-3 top column left`}>
                            <div className={`row nowrap w-flex mb-3`}>
                                <p data-size={5} className={`semibold seminarrow`}>
                                    {`People that match your request`}
                                </p>
                                <div className={`block label btn-circled-24 desktop neg-my`} data-color={`light-gray`}>
                                    <p style={{whiteSpace: `nowrap`}} data-size={6} className={`regular`}>{`${recommendationsUserNumber + 1}/${recommendations?.length}`}</p>
                                </div>
                            </div>

                            {showRelevanceSurvey ? <>
                            <div className={`block w-flex b-3 p-3 mb-3 h-flex column top left`} data-border={`${rndColor[0]}-4`}>
                                <p data-size={5} className={`semibold seminarrow mb-3 noshrink`}>
                                    {`Give us your feedback on ${recommendations[recommendationsUserNumber]?.user?.fname ?? 'user'}’s profile`}
                                </p>

                                {relevanceOptions.map((elem: string, index: number) => {return (
                                    <button className={`btn desktop row top nowrap left w-flex ${index + 1 < relevanceOptions?.length ? 'mb-1' : ''}`} key={index}
                                            onClick={() => {setRelevanceOption(index)}}>
                                        <div className={`checkbox-radio w-3 h-3 b-2 centered noshrink`} data-checked={relevanceOption === index}>
                                            <div className={`checkbox-center h-1 w-1 b`}></div>
                                        </div>
                                        <p data-size={8} data-color={relevanceOption === index ? 'black' : 'dark-gray'}>{elem}</p> 
                                    </button>
                                )})}

                                <textarea className={`desktop w-flex h-flex b-1 p-2 mt-2`} value={relevanceDescription} onChange={e => { 
                                                setRelevanceDescription(e.target.value);
                                                setRelevanceOption(val => !val ? relevanceOptions.findIndex(val_ => val_ === "Other") : val);
                                            }} data-size={8}
                                        placeholder={`Share deeper insights. Provide additional feedback to help us understand your preferences better.`}></textarea>
                            </div>

                            <div className={`row w-flex px-3`}>
                                <button className={`block btn-circled-40 desktop w-7 row center`} data-color={`black`}
                                        disabled={relevanceOption === undefined || (relevanceOptions[relevanceOption] === "Other" && !relevanceDescription?.length)}
                                        onClick={() => {
                                            RelevanceRequest(recommendations[recommendationsUserNumber]?.user?.id, [relevanceOptions[relevanceOption ?? 2], relevanceDescription ?? ''], false, () => {
                                                setRecommendations(val => SetDict(
                                                    val, 
                                                    [recommendationsUserNumber, "user", "notRelevant"], 
                                                    true
                                                ));
                                                setShowRelevanceSurvey(false);
                                                setRelevanceDescription('');
                                                setRelevanceOption(undefined);
                                                if (recommendations?.length === 1) {
                                                    context.setProfile((val: any) => SetDict(
                                                        val, [`requests`, requestNumber, 'reloadRecommendations'], 
                                                        (val?.requests[requestNumber]?.reloadRecommendations ?? -1) + 1,
                                                    ))
                                                }
                                                setRecommendations(val => val.filter((val_, index: number) => index !== recommendationsUserNumber));
                                            });
                                        }}>
                                    <p>{`Send`}</p>
                                </button>
                                <button className={`block btn-circled-40 desktop w-7 row center`} data-color={`light-gray`}
                                        onClick={() => {
                                            setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length);
                                        }}>
                                    <p>{`Skip`}</p>
                                </button>
                            </div>
                            </> : <>
                            <div className={`w-flex-p-3 neg-mx-3 px-5 h-flex mb-3 row nowrap gap-3`} ref={scrollRecommendationsBlock}
                                    onScroll={e => { e.preventDefault() }} style={{overflow: `hidden`}}>
                                {recommendations.map((rec_elem, index: number) => {return (
                                <div className={`block w-flex b-3 p-3 h-flex y-auto noshrink`} 
                                    data-border={index === recommendationsUserNumber ? `gray-4` : `white-4`} 
                                    key={index} data-color={index === recommendationsUserNumber ? null : `dark-white`}>
                                    <div className={`w-flex`} style={{
                                        transition: `opacity 0.5s ease-in-out`,
                                        opacity: index === recommendationsUserNumber ? 1 : 0,
                                    }}>
                                        <div className={`block w-flex row gap-2 left top nowrap`} style={{position: `relative`}}>
                                            <div className={`label block btn-rectangle-24 desktop`}
                                                style={{position: `absolute`, bottom: 4, left: 4}} hidden={!rec_elem?.user?.responsive}>
                                                <p>{`Responsive`}</p>
                                            </div>
                                            <NewAvatar size={9} name={rec_elem?.user?.name} 
                                                    avatar={rec_elem?.user?.avatar?.link}
                                                    type={rec_elem?.user?.avatar?.style} style={{transitionDuration: `0s`}}/>
                                            <div className={`left column`} style={{
                                                    minHeight: 198,
                                                    width: `calc(100% - 212px)`,
                                                }}>
                                                <div className={`w-flex`}>
                                                    <div className={`row w-flex nowrap`}>
                                                        <a href={`/profile/${rec_elem?.user?.unique_id ?? rec_elem?.user?.id}`}>
                                                            <p data-size={8} className={`semibold seminarrow`} data-color={`black`}>{rec_elem?.user?.name}</p>
                                                        </a>                           
                                                        <div className={`row left nowrap nogap`} 
                                                            hidden={!rec_elem?.user?.location?.city}>
                                                            <img src={LocationBrightBlue} alt={``} style={{width: 16, height: 16}}></img>
                                                            <p data-size={7} data-color={"bright-blue"} className={`w-flex ellipsis seminarrow semibold`}>
                                                                {rec_elem?.user?.location?.city}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p data-size={6} data-lines={2} data-color={`black-40-opacity`}>
                                                        {rec_elem?.user?.occupation}
                                                    </p> 
                                                    {rec_elem?.user?.industry?.length > 0 &&
                                                    <div className={`my row left w-flex h-3`} style={{overflow: `hidden`}}>
                                                        {rec_elem?.user?.industry.map((elem: string, index: number) => {return (
                                                            <div className={`label block btn-circled-24 desktop mb-2`} key={index} data-border={true}
                                                                    style={{border: `solid 1px ${CreateHSLColor(elem)}`}}>
                                                                <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                                                            </div>
                                                        )})}
                                                    </div>}
                                                    <div className={`w-flex`} id={`read-more-about`} data-index={index} 
                                                        data-lines={rec_elem?.user?.openAbout ? null : 4}>
                                                        <p data-size={6}>
                                                            {rec_elem?.user?.about}
                                                        </p>
                                                    </div>
                                                    <p data-size={6} className={`semibold seminarrow clickable`} 
                                                    onClick={() => { setRecommendations(val => SetDict(val, [recommendationsUserNumber, "user", "openAbout"], true)) }}
                                                    hidden={ rec_elem?.user?.openAbout }>
                                                        {`Read more`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {!!OnlyValid(rec_elem?.user?.requests)?.length &&
                                        <div className={`block p-2 pb b-2 w-flex mt-2`} data-color={`light-gray`}>
                                            <div className={`row left nowrap px-1 mb-2`}>
                                                <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                                    <p data-size={6} className={`px-1`}>{OnlyValid(rec_elem?.user?.requests)?.length}</p>
                                                </div>
                                                <p data-size={8} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Requests`}</p>
                                            </div>
                                            {OnlyValid(rec_elem?.user?.requests).map((elem: any, index_: number) => {return (<Fragment key={index_}>
                                            <div className={`w-flex p-2 clickable mb-1 block b-2`} onClick={() => { 
                                                const newWindow: WindowProxy | null = window.open(`/request/${elem?.id}`, '_blank');
                                                if (newWindow) {
                                                    newWindow.focus();
                                                }
                                            }}>
                                                <div className={`neg-mx-1 px-1 w-flex-p-1`}
                                                    data-lines={elem?.openRequest ? null : 3}
                                                    id={`read-more-requests-${index_}`} data-index={index}>
                                                    {elem?.answers.reduce((init: string, elem: string) => {
                                                        return `${init}${init && '\n'}${elem.trim()}`;
                                                    }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem !== '' ?
                                                    <p data-size={6} className={`article`} data-color={'black'} key={index}>
                                                        {elem}
                                                    </p> : null)})}
                                                </div>
                                                <p data-size={6} className={`semibold seminarrow clickable`} 
                                                    onClick={e => { 
                                                        e.stopPropagation();
                                                        setRecommendations(val => SetDict(val, [recommendationsUserNumber, "user", "requests", index_, "openRequest"], true)); 
                                                    }}
                                                    hidden={ elem?.openRequest }>
                                                    {`Read more`}
                                                </p>
                                                <div className={`row mt-1 w-flex row`}>                            
                                                    <button className={`block btn-circled-32 desktop row`} 
                                                            data-color={`black`}
                                                            disabled={context.profile?.is_requested.includes(elem?.id)}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                // setWishToHelpRequest1({...elem, user_id: elem?.user?.id});
                                                                // setWishToHelpPopup1(true);
                                                                context.setNewChatOffer({
                                                                    created_at: Date.now() / 1000,    
                                                                    items: [],
                                                                    pair: rec_elem?.user,
                                                                    user: context.profile,
                                                                    is_new_requested: true,
                                                                    is_can_help: true, 
                                                                    refer_to_request: elem,
                                                                });
                                                                context.setPage(2, 0);
                                                                context.setTab(0, 2);
                                                            }} type={`button`} id={`wish-to-match`}>
                                                        <p>{`I can help`}</p>
                                                    </button>
                                                    
                                                    <div className={`row left nowrap`}>
                                                        <button className={`block btn-circled-32 desktop row p-both`} data-color={'light-gray'}
                                                                onClick={e => {
                                                                    e.stopPropagation();  
                                                                    ClipBoard(`${window.location.origin}/share/${elem?.id}?from_user=${cookie[`user_id_cookie`]}`, () => {
                                                                        globalContext.setClipboardOk(true);
                                                                    })
                                                                    // if (window.isSecureContext) {
                                                                    //     navigator.clipboard.writeText(`${window.location.origin}/share/${elem?.id}`)
                                                                    //     .then(() => {
                                                                    //         context.setClipboardOk(true);
                                                                    //     });
                                                                    // }
                                                                }} type={'button'} id={`share-feed-btn`} disabled={false}>
                                                            <img src={ShareBlack} alt={``} className={`w-3`}></img>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>)})}
                                        </div>}

                                        {!!rec_elem?.user?.projects?.length && 
                                        <div className={`block p-3 b-2 w-flex mt-2`} data-color={`light-gray`} ref={projectScrollBlock}
                                                onMouseEnter={() => {
                                                if (projectScrollElem.current && 
                                                    projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                                    if (IsLeftScroll(projectScrollElem.current)) {
                                                        setShowLeftProjectBtn(true);
                                                    }
                                                    if (IsRightScroll(projectScrollElem.current)) {
                                                        setShowRightProjectBtn(true);
                                                    }
                                                }
                                                }} onMouseLeave={() => { 
                                                setShowLeftProjectBtn(false); 
                                                setShowRightProjectBtn(false);
                                            }}>
                                            <div className={`row nowrap`}>
                                                <div className={`row left nowrap`}>
                                                    <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                                        <p data-size={6} className={`px-1`}>{recommendations[recommendationsUserNumber]?.user?.projects?.length}</p>
                                                    </div>
                                                    <p data-size={5} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Projects`}</p>
                                                </div>
                                            </div>
                                            <div className={`row nogap nowrap min-w-flex neg-mx-3 mt-3`}>
                                                <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftProjectBtn} style={{zIndex: 1}}>
                                                    <button className={`block btn centered w-4 h-4 b-4`} style={{
                                                        boxShadow: '0 0 13px #00000020',
                                                        transform: 'translate(-29px, 0) rotate(180deg)'
                                                    }} data-color={'white'} onClick={() => {
                                                        if (projectScrollElem.current) {
                                                            projectScrollElem.current.scrollTo({
                                                                left: projectScrollElem.current.scrollLeft - 278, 
                                                                behavior: 'smooth'
                                                            });
                                                        }
                                                    }}>
                                                        <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                                    </button>
                                                </div>

                                                <div className={`row x-auto left top noshrink nowrap nogap w-flex px-3`} ref={projectScrollElem}
                                                    onScroll={() => {
                                                        if (projectScrollElem.current && 
                                                            projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                                            if (IsLeftScroll(projectScrollElem.current)) {
                                                                setShowLeftProjectBtn(true);
                                                            } else {
                                                                setShowLeftProjectBtn(false);
                                                            }
                                                            if (IsRightScroll(projectScrollElem.current)) {
                                                                setShowRightProjectBtn(true);
                                                            } else {
                                                                setShowRightProjectBtn(false);
                                                            }
                                                        }
                                                    }} >
                                                    {rec_elem?.user?.projects.map((elem : any, index: number) => {return (
                                                        <SiteCardSmall project={elem} key={index} className={`${!!index ? `ml-2` : ``}`} 
                                                                    desktop={true} orderNumber={index}/>
                                                    )})}
                                                </div> 

                                                <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightProjectBtn}>
                                                    <button className={`block btn centered w-4 h-4 b-4`} style={{
                                                        boxShadow: '0 0 13px #00000020',
                                                        transform: 'translate(29px, 0)'
                                                    }} data-color={'white'} onClick={() => {
                                                        if (projectScrollElem.current) {
                                                            projectScrollElem.current.scrollTo({
                                                                left: projectScrollElem.current.scrollLeft + 278, 
                                                                behavior: 'smooth'
                                                            });
                                                        }
                                                    }}>
                                                        <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                                    </button>
                                                </div>
                                            </div> 
                                        </div>}
                                    </div>
                                </div>
                                )})}
                            </div>
                            
                            <div className={`row w-flex`}>
                                <button className={`block btn-circled-40 desktop row p-left p-right`} data-color={`light-gray`}
                                        onClick={() => { setRecommendationsUserNumber(val => Math.max(0, val - 1)) }}>
                                    <div className={`btn-symbol centered w-3 h-3`} style={{transform: `rotate(180deg)`}}>
                                        <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                                    </div>
                                </button>
                                <button className={`block btn-circled-40 desktop w-7 row center`} data-color={`light-gray`}
                                        onClick={() => {
                                            setShowRelevanceSurvey(true);
                                        }}>
                                    <p>{`Not relevant`}</p>
                                </button>
                                <button className={`block btn-circled-40 desktop w-7 row center`} data-color={`black`}
                                        onClick={() => {
                                            // setWishToHelpRequest2({
                                            //     ...profile?.requests[requestNumber],                                                            
                                            //     avatar: profile?.avatar,
                                            //     name: profile?.name,
                                            //     occupation: profile?.occupation,
                                            //     user_id: recommendations[recommendationsUserNumber]?.user?.id,
                                            //     onSent: () => { setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length) }
                                            // });
                                            // setWishToHelpPopup2(true);
                                            context.setNewChatOffer({
                                                created_at: Date.now() / 1000,    
                                                items: [],
                                                pair: recommendations[recommendationsUserNumber]?.user,
                                                user: context.profile,
                                                is_new_requested: true, 
                                                refer_to_request: context.profile?.requests[requestNumber],
                                            });
                                            context.setPage(2, 0);
                                            context.setTab(0, 2);
                                        }}>
                                    <p>{`Contact me`}</p>
                                </button>
                                <button className={`block btn-circled-40 desktop w-7 row center`} data-color={`light-gray`}
                                        onClick={() => { 
                                            Subscribe(
                                                cookie[`user_id_cookie`],
                                                recommendations[recommendationsUserNumber]?.user?.id,
                                                !recommendations[recommendationsUserNumber]?.user?.followed,
                                                () => { 
                                                    setRecommendations(val => SetDict(
                                                        val, 
                                                        [recommendationsUserNumber, "user", "followed"], 
                                                        !recommendations[recommendationsUserNumber]?.user?.followed
                                                    ));
                                                    setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length);
                                                },
                                            )
                                        }}>
                                    <p>{recommendations[recommendationsUserNumber]?.user?.followed ? `Unfollow` : `Follow`}</p>
                                </button>
                                <button className={`block btn-circled-40 desktop row p-left p-right`} data-color={`light-gray`}
                                        onClick={() => { setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length) }}>
                                    <div className={`btn-symbol centered w-3 h-3`}>
                                        <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                                    </div>
                                </button>
                            </div></>}
                        </div> :
                        <div className={`block w-flex h-flex b-3 p-3 top column left`}>
                            <p data-size={5} className={`semibold seminarrow mb-3`}>
                                {`Searching for your personalized match list based on your request...`}
                            </p>
                            <div className={`row w-flex center nowrap gap-0 mb-1`}>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((elem: number) => {return (
                                    <div className={`b-1 skeleton`} key={elem} style={{
                                        opacity: elem > recommendationsLoadingText * 2 + 1 ? 0.5 : 1,
                                        width: 70, height: 14,
                                    }}></div>
                                )})}
                            </div>
                            <p data-size={8} className={`mb-3`}>
                                {texts[recommendationsLoadingText]}
                            </p>
                            <div className={`w-flex-p-3 neg-mx-3 mb-3 px-5 h-flex`}>
                                <div className={`block w-flex h-flex b-3 skeleton`}></div>
                            </div>
                            <div className={`row w-flex nowrap`}>
                                <div className={`block label btn-circled-40 desktop skeleton p-left p-right`}><div className={`w-3 h-3`}></div></div>
                                <div className={`block label btn-circled-40 desktop w-7 skeleton`}><p>{`Not relevant`}</p></div>
                                <div className={`block label btn-circled-40 desktop w-7 skeleton`}><p>{`Contact me`}</p></div>
                                <div className={`block label btn-circled-40 desktop w-7 skeleton`}><p>{`Follow`}</p></div>
                                <div className={`block label btn-circled-40 desktop skeleton p-left p-right`}><div className={`w-3 h-3`}></div></div>
                            </div>
                        </div>}
                    </div>) :
                    <div className={`skeleton w-flex b-3 h-chat mb-3`}></div>}
                </div>     

                {showDeleteRequestPopup &&
                <ConfirmPopup title={`This action can't be undone`} subtitle={`Are you sure you want to delete your\u00A0request?`} 
                            show={showDeleteRequestPopup} setShow={setShowDeleteRequestPopup} _id={`yesno`}
                            color_2={'light-gray'} buttonTitle_2={`Cancel`} onClick_2={() => {}} black={true} desktop={true} white={true}
                            color_1={'coral'} buttonTitle_1={`Delete`} onClick_1={() => {
                                CardDelete(showDeleteRequestPopup, () => {
                                    setShowDeleteRequestPopup(null); 
                                    context.UpdateData();
                                }, () => {
                                    setShowDeleteRequestPopup(null); 
                                })
                            }} close={'second'}/>}

                {editRequestPopup &&  
                <AddRequestDesktop show={editRequestPopup} setShow={setEditRequestPopup} canClose={true}
                                onSubmit={() => {
                                    context.UpdateData();
                                }} onDelete={() => {
                                    context.UpdateData();
                                }} onClose={() => { 
                                    setEditRequestPopup(false);
                                }} canDelete={context.profile?.requests?.length > 1}
                                headerId={`add-request-popup-content`} request={editRequestPopup}/>} 
            </div>
        </div>
    )
}

const BecomeAMemberBlock = () => {
    const [ waitStripe, setWaitStripe ] = useState(false);

    return (
        <div className="w-flex">
            <div className="block bt-3 p-3 w-flex" data-color={"blue"}>
                <p data-size={9} className={`semibold seminarrow mb-1`}>
                    {`Become a member`}<br/> 
                    {`and get your `}<span data-color={"green"}>{`expert match next Monday`}</span>
                </p>
                <p data-size={5} data-color={"white-40-opacity"}>
                    {`Becoming a paid member signifies your serious intentions`}<br/> 
                    {`respecting everyone's time.`}
                </p>
            </div>
            <div className="block bb-3 p-3 w-flex">
                <p data-size={5} className={`mb-2`}>
                    {`3 000 professionals from AI, VC, FinTech, MarTech, Education, Media, E-commerce, Web3, and more`}
                </p>

                {[
                    `Discover new ideas and insights.`,
                    `Get support.`,
                    `Find opportunities for collaboration and professional development.`,
                ].map((elem: string, index: number) => {return (
                    <div key={index} className={`row left gap-2 nowrap px-2 mb-2`}>
                        <img src={StarYellow} className={`w-3 h-3`} alt={``}></img>
                        <p data-size={5}>{elem}</p>
                    </div>
                )})}
                <p data-size={5}>
                    {`Connect with people who inspire and help each other.`}
                </p>

                <button className={`block btn-flex desktop high row center w-8 mb-1 mt-3`} onClick={() => {
                    setWaitStripe(true);
                    OpenStripePortal(false, () => { setWaitStripe(false) }, `/profile/match?first_login=true&welcome=true`, 5)
                }} data-color={`black`}>
                    {waitStripe ? <Loading width={24} height={24} color={`white`}/> : 
                    <p>{`$59.99/month`}</p>}
                </button>
                <p data-size={8} data-color={`black-40-opacity`}>
                    {`Subscriptions renew automatically. Cancel anytime.`}
                </p>
            </div>
        </div>
    )
}    

export const ProfileMatchDesktop = (props: any) => {
    const context = useContext(ProfileContext);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ waitReadyStatus, setWaitReadyStatus ] = useState<boolean>(false);
    const [ feedBackPopup, setFeedBackPopup ] = useState<any>();

    const [ match, setMatch ] = useState<any>();
    const [ matchTab, setMatchTab ] = useState<number>(0);    
    const [ isReadMore, setIsReadMore ] = useState<boolean[]>([]);  
    
    const [ toRateProfiles, setToRateProfiles ] = useState<any>();
    const [ toRateSelectedNum, setToRateSelectedNum ] = useState<number>();
    const [ toRateRatedNum, setToRateRatedNum] = useState<number>(0);
    const [ rateFlow, setRateFlow] = useState<"select" | "rate">();

    const projectScrollBlock = useRef<HTMLDivElement | null>(null);
    const projectScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftProjectBtn, setShowLeftProjectBtn ] = useState<boolean>(false);
    const [ showRightProjectBtn, setShowRightProjectBtn ] = useState<boolean>(false);

    useEffect(() => {
        setLoaded(context.profile?.is_ready !== undefined && match !== undefined && (
            !context.profile?.subscription?.first_flow ||
            !!toRateProfiles?.length
        ));
    }, [context.profile, match, toRateProfiles])

    useEffect(() => {
        setWaitReadyStatus(false);
    }, [context.profile?.is_ready])

    useEffect(() => {
        setIsReadMore(matchTab === 1 ? OnlyValid(match?.requests).reduce((init: boolean[], elem: any) => {
            return init.concat([
                (document.querySelector(`#feed-card-about-${elem?.id}`) as HTMLDivElement)?.offsetHeight < (document.querySelector(`#feed-card-about-${elem?.id}`) as HTMLDivElement)?.scrollHeight
            ]);
        }, []) : [])

        const scrollBlock = document.querySelector(`#match-scroll-block`) as HTMLDivElement;
        if (scrollBlock) {
            scrollBlock.scrollTo({
                top: 0,
                behavior: `instant`,
            })
        }
    }, [match?.requests, matchTab]) 

    useEffect(() => {
        if (match && loaded) {
            localStorage.setItem("seen_as_match", match?.id)
        }
    }, [loaded, match])

    useEffect(() => {
        MatchRequest(setMatch, {
            404: () => { setMatch(null) },
        });

        ToRateRequest(setToRateProfiles);
    }, [])

    useEffect(() => {
        if (toRateProfiles?.length > 1) {
            setRateFlow(Math.floor(Math.random() * 3) === 0 ? "select" : 'rate');
        }
    }, [toRateProfiles?.length])

    useEffect(() => {
        if (rateFlow) {
            toRateProfiles?.forEach((elem: any, index: number) => {
                const aboutElem = document.querySelector(`#rate-user-about-${elem?.user?.id}`) as HTMLPreElement;
                if (aboutElem) {
                    setToRateProfiles((val: any) => SetDict(
                        val, [index, "user", "readMore"], 
                        aboutElem.scrollHeight > (rateFlow === "select" ? 157 : 90),
                    ))
                }
            })
        }
    }, [rateFlow, toRateSelectedNum, toRateRatedNum])

    return (
        <div className={`row center w-flex`}>
            <div className={`w-10 sm-w-flex pb-6 column`} style={{
                flexDirection: match?.is_met ? `column-reverse` : `column`,
                gap: 24,
            }}>
                {loaded ? <>  
                {!!match ? (match?.seen_as_match ?
                <div className={`block b-3 p-3 column left w-flex`}>
                    <div className={`row nowrap w-flex`}>
                        <a className={`row left nowrap gap-2`} href={`/profile/${match?.unique_id ?? match?.id}`}>
                            <NewAvatar name={match?.name} avatar={match?.avatar?.link} 
                                    size={5} type={0}/>  
                            <div className={`w-flex`}> 
                                <p data-color={`black`} data-size={4} className={`semibold seminarrow mb`}>
                                    {match?.name}
                                </p>
                                <p data-size={8} data-color={`black-40-opacity`} data-lines={2}>
                                    {match?.occupation}
                                </p>
                            </div> 
                        </a>
                        {match?.is_met ?
                        <button className={`block btn-flex desktop noshrink`} data-color={`light-gray`} 
                                onClick={() => { setFeedBackPopup(match) }}>
                            <p>{`Share feedback`}</p>
                        </button> :
                        <button className={`block btn-flex desktop noshrink`} data-color={`green`}
                                onClick={() => {
                                    context.setNewChatOffer({
                                        created_at: Date.now() / 1000,    
                                        items: [],
                                        pair: match,
                                        user: context.profile,
                                        is_new_requested: true, 
                                        is_chat_offer: true,
                                    });
                                    context.setPage(2, 0);
                                    context.setTab(0, 2);
                                }}>
                            <p>{`Arrange a meeting`}</p>
                        </button>}       
                    </div> 
                </div> :
                <div className={`block b-3 p-3 w-flex h-chat column left`}>
                    <div className={`w-flex`}>
                        <div className={`row left top gap-2 nowrap mb-3`}>
                            <NewAvatar name={match?.name} avatar={match?.avatar?.link} 
                                    size={6} className={`circled`} type={0}/>  
                            <div> 
                                <p style={{fontSize: 32}} className={`semibold seminarrow mb`}>
                                    {match?.name}
                                </p>
                                <p data-size={8} data-color={`black-40-opacity`} data-lines={2}>
                                    {match?.occupation}
                                </p>
                            </div> 
                        </div> 
                        <div className={`row left nowrap`}>
                            {["About", "Requests", "Projects"].map((elem: string, index: number) => 
                            <button key={index} className={`block btn-circled-40 desktop`} 
                                    data-color={index === matchTab ? `black-40-opacity`: `light-gray`}
                                    onClick={() => { setMatchTab(index) }}>
                                <p>{elem}</p>
                            </button>)}
                        </div>   
                    </div> 
                    <div className={`w-flex h-4 neg-mb-4 noshrink`} style={{
                        background: `linear-gradient(rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0))`,
                        position: `relative`, zIndex: 2
                    }}></div>                      
                    <div className={`w-flex-p-3 neg-mx-3 y-auto p-3 h-flex`} id={`match-scroll-block`}> 
                        {matchTab === 0 && <>                    
                        {match?.industry?.length > 0 &&
                        <div className={`row w-flex nowrap mb-1`}>
                            <div className={`my row left w-flex`} style={{overflow: `hidden`, height: 19}}>
                                {match?.industry.map((elem: string, index: number) => {return (
                                    <p data-size={8} style={{color: CreateHSLColor(elem)}} 
                                    className={`semibold seminarrow`} key={index}>{elem}</p>
                                )})}
                            </div> 
                        </div>} 
                        <p data-size={8} data-lines={true} className={`mb-1`}>
                            {(match?.about ?? '').trim()
                            .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                                <span className={`article`} key={index}>{elem_}</span> : null
                            )})}
                        </p>
                        <div className={`neg-mb-1`}></div>
                        </>} 
                        {matchTab === 1 &&  
                        OnlyValid(match?.requests).map((elem: any, index: number) => {return (
                        <div key={index} className={`block w-flex p-2 b-2 mb-1 clickable hover-dark-white`} onClick={() => { 
                            if (window.getSelection()?.type !== "Range") {
                                const newWindow: WindowProxy | null = window.open(`/request/${elem?.id}`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }
                        }} data-border={`gray-1`}>
                            <div className={`neg-mx-1 px-1 w-flex-p-1`} data-lines={4}
                                    id={`feed-card-about-${elem?.id}`} data-scroll={isReadMore?.length > 0 && isReadMore[index] === false}>
                                {elem?.answers.reduce((init: string, elem: string) => {
                                    return `${init}${init && '\n'}${elem.trim()}`;
                                }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem !== '' ?
                                <p data-size={6} className={`article`} data-color={'black'} key={index}>
                                    {elem}
                                </p> : null)})}
                            </div>

                            {isReadMore[index] &&
                            <button className={`btn`} onClick={e => {
                                        e.stopPropagation();
                                        setIsReadMore(val => SetDict(val, [index], false));
                                    }}>
                                <p data-size={6} className={`semibold hover mt`} data-color={'black'}>
                                    {`Read more`}
                                </p>
                            </button>}
                        </div>)})} 
                        {matchTab === 2 && 
                        <div className={`w-flex-p-3 neg-mx-3`} ref={projectScrollBlock}
                                onMouseEnter={() => {
                                if (projectScrollElem.current && 
                                    projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                    if (IsLeftScroll(projectScrollElem.current)) {
                                        setShowLeftProjectBtn(true);
                                    }
                                    if (IsRightScroll(projectScrollElem.current)) {
                                        setShowRightProjectBtn(true);
                                    }
                                }
                                }} onMouseLeave={() => { 
                                setShowLeftProjectBtn(false); 
                                setShowRightProjectBtn(false);
                            }}>
                            <div className={`row nogap nowrap w-flex`}>
                                <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftProjectBtn} style={{zIndex: 1}}>
                                    <button className={`block btn centered w-4 h-4 b-4`} style={{
                                        boxShadow: '0 0 13px #00000020',
                                        transform: 'rotate(180deg)',
                                    }} data-color={'white'} onClick={() => {
                                        if (projectScrollElem.current) {
                                            projectScrollElem.current.scrollTo({
                                                left: projectScrollElem.current.scrollLeft - 278, 
                                                behavior: 'smooth'
                                            });
                                        }
                                    }}>
                                        <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                    </button>
                                </div>
        
                                <div className={`row x-auto left top noshrink nowrap gap-2 w-flex px-3`} ref={projectScrollElem}
                                    onScroll={() => {
                                        if (projectScrollElem.current && 
                                            projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                            if (IsLeftScroll(projectScrollElem.current)) {
                                                setShowLeftProjectBtn(true);
                                            } else {
                                                setShowLeftProjectBtn(false);
                                            }
                                            if (IsRightScroll(projectScrollElem.current)) {
                                                setShowRightProjectBtn(true);
                                            } else {
                                                setShowRightProjectBtn(false);
                                            }
                                        }
                                    }} >
                                    {context.profile?.projects.map((elem : any, index: number) => {return (
                                        <SiteCard project={elem} key={index}
                                                onUpdate={() => {
                                                        context.UpdateData();
                                                }} desktop={true} orderNumber={index} lower={true}/>
                                    )})}
                                </div> 
        
                                <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightProjectBtn}>
                                    <button className={`block btn centered w-4 h-4 b-4`} style={{
                                        boxShadow: '0 0 13px #00000020', zIndex: 2,
                                    }} data-color={'white'} onClick={() => {
                                        if (projectScrollElem.current) {
                                            projectScrollElem.current.scrollTo({
                                                left: projectScrollElem.current.scrollLeft + 278, 
                                                behavior: 'smooth'
                                            });
                                        }
                                    }}>
                                        <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                    </button>
                                </div>
                            </div> 
                        </div>}
                    </div>                    
                    <div className={`w-flex h-4 neg-mt-4 noshrink`} style={{
                        background: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%)`,
                        position: `relative`, zIndex: 2
                    }}></div> 
                    {match?.is_met ?
                    <button className={`block btn-flex desktop noshrink`} data-color={`light-gray`} 
                            onClick={() => { setFeedBackPopup(match) }}>
                        <p>{`Share feedback`}</p>
                    </button> :
                    <button className={`block btn-flex desktop noshrink`} data-color={`green`} onClick={() => {
                        context.setNewChatOffer({
                            created_at: Date.now() / 1000,    
                            items: [],
                            pair: match,
                            user: context.profile,
                            is_new_requested: true, 
                            is_chat_offer: true,
                        });
                        context.setPage(2, 0);
                        context.setTab(0, 2);
                    }}>
                        <p>{`Arrange a meeting`}</p>
                    </button>}
                </div>) : (((
                    context.profile?.is_ready ||
                    context.profile?.subscription?.first_flow
                ) && rateFlow) ?
                <div className="block b-3 p-3 w-flex">
                    {rateFlow === "rate" ? <>
                    <p data-size={5} className={`semibold seminarrow mb-1`}>
                        {`Rate profiles' relevance to get better matches!`}
                    </p>
                    <p data-size={8} className={`mb-3`}>
                        {`Each profile you rate helps our algorithm become more accurate.`}<br/> 
                        {`Simply choose how relevant each professional is to your goals.`}
                    </p>
                    <div className={`block p-2 b-2 mb-3`} data-border={`gray-1`} style={{minHeight: 176.5}}>
                        <div className={`row top nowrap mb-1`}>
                            <div className={`row left nowrap`}>
                                <div className={`block b-1 noshrink`} data-color={`gray`} style={{
                                    width: 49, height: 49,
                                    backgroundImage: `url("${(toRateProfiles ?? [])[toRateRatedNum]?.user?.avatar?.link}")`,
                                }}></div>
                                <div>
                                    <p data-size={6} data-color={`black-40-opacity`} data-lines={1}>
                                        {(toRateProfiles ?? [])[toRateRatedNum]?.user?.occupation}
                                    </p>
                                    <div className={`row left w-flex h-3 mt neg-mb`} style={{overflow: `hidden`}}>
                                        {(toRateProfiles ?? [])[toRateRatedNum]?.user?.industry.map((elem: string, index: number) => {return (
                                            <p data-size={8} style={{color: CreateHSLColor(elem)}} 
                                            className={`semibold seminarrow`} key={index}>{elem}</p>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            <div className={`block b-3 h-3 min-w-4 centered noshrink`} data-color={`green`}>
                                <p data-size={6}>{`${toRateRatedNum + 1}/${toRateProfiles?.length}`}</p>
                            </div>
                        </div>
                        
                        <div className={`w-flex-p-1 neg-mx-1 neg-my p-1 py b-1`}>
                            <p data-size={8} data-lines={4} data-scroll={(toRateProfiles ?? [])[toRateRatedNum]?.user?.readMore === false} 
                               id={`rate-user-about-${(toRateProfiles ?? [])[toRateRatedNum]?.user?.id}`}>
                                {((toRateProfiles ?? [])[toRateRatedNum]?.user?.about ?? '').trim()
                                .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                                    <span className={`article`} key={index}>{elem_}</span> : null
                                )})}
                            </p>
                            {(toRateProfiles ?? [])[toRateRatedNum]?.user?.readMore &&                             
                            <button className={`btn mobile`} onClick={e => {
                                e.stopPropagation();
                                setToRateProfiles((val: any) => SetDict(
                                    val, [toRateRatedNum, "user", "readMore"], 
                                    false,
                                ))
                            }}>
                                <p data-size={8} className={`semibold seminarrow pt`}>
                                    {`Read more`}
                                </p>
                            </button>}
                        </div>
                    </div>

                    <input type={`range`} className={`range-desktop w-flex mb-3 neg-mt-1`} min={0} max={100} 
                           value={(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating ?? 0} 
                           data-full={(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating === 100}
                       onChange={e => { setToRateProfiles((val: any) => SetDict(val, [toRateRatedNum, "user", "rating"], Number(e.target.value))) }}
                       style={{backgroundSize: `${(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating ?? 0}% 100%`}}></input>
                    <div className={`row w-flex nowrap px-1 mb-4`}>
                        {["Not relevant", "Highly relevant"].map((elem: string, index: number) => 
                        <p data-size={8} key={index}>{elem}</p>
                        )}
                    </div>
                    
                    <div className={`grid-1-1 w-flex`} style={{gap: 8}}>
                        <button className={`block btn-flex desktop row center`} data-color={`light-gray`} 
                                onClick={() => { 
                                    ToRateSkip();
                                    setRateFlow(undefined);
                                    setToRateProfiles(null);
                                }}>
                            <p>{`Skip`}</p>
                        </button>
                        <button className={`block btn-flex desktop row center`} data-color={`black`} 
                                onClick={() => { 
                                    if (toRateRatedNum + 1 >= toRateProfiles?.length) {
                                        (toRateProfiles ?? []).forEach((elem: any) => {
                                            RelevanceRequest(
                                                elem?.user?.id, 
                                                [String(elem?.user?.rating)], 
                                                elem?.user?.rating >= 50,
                                            )
                                        })
                                        setRateFlow(undefined);
                                        setToRateProfiles(null);
                                    } else {
                                        setToRateRatedNum(val => Math.min(val + 1, toRateProfiles?.length - 1));
                                    } 
                                }}
                                disabled={(toRateProfiles ?? [])[toRateRatedNum]?.user?.rating === undefined}>
                            <p>{`Next`}</p>
                        </button>
                    </div>
                    </> : <>
                    <div className={`row nowrap w-flex mb-1`}>
                        <p data-size={5} className={`semibold seminarrow`}>
                            {`Pick the most relevant profile`}
                        </p>  
                        <div className={`block b-3 h-3 min-w-4 centered noshrink`} data-color={`green`}>
                            <p data-size={6}>{`${toRateRatedNum + 1}/${Math.floor(toRateProfiles?.length / 2)}`}</p>
                        </div> 
                    </div>                 
                    <p data-size={8} className={`mb-3`}>
                        {`Take a quick 2-minute test to help us understand your goals.`}<br/> 
                        {`We'll connect you with the ideal expert for your unique needs.`}
                    </p>
                    <div className="grid-1-1 w-flex mb-4" style={{gap: 14}}>
                        {(toRateProfiles ?? []).slice(toRateRatedNum * 2, toRateRatedNum * 2 + 2).map((elem: any, index: number) => 
                        <div className={`block p-2 b-2 w-flex h-flex clickable`} data-color={`light-gray`}
                             onClick={() => { setToRateSelectedNum(index) }} style={index === toRateSelectedNum ? {
                                boxShadow: `0 0 0 4px rgb(255, 255, 255), 0 0 0 6px rgba(0, 0, 0, 0.4)`,
                                padding: 8, margin: 6, width: `calc(100% - 12px)`, height: `calc(100% - 12px)`,
                             } : {}}>
                            <div className={`row left nowrap mb-1`}>
                                <div className={`block b-1 noshrink`} data-color={`gray`} style={{
                                    width: 49, height: 49,
                                    backgroundImage: `url("${elem?.user?.avatar?.link}")`,
                                }}></div>
                                <div>
                                    <p data-size={6} data-color={`black-40-opacity`} data-lines={1}>
                                        {elem?.user?.occupation}
                                    </p>
                                    <div className={`row left w-flex h-3 mt neg-mb`} style={{overflow: `hidden`}}>
                                        {elem?.user?.industry.map((elem: string, index: number) => {return (
                                            <p data-size={8} style={{color: CreateHSLColor(elem)}} 
                                            className={`semibold seminarrow`} key={index}>{elem}</p>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            
                            <div className={`w-flex-p neg-m p b-1`}>
                                <p data-size={8} data-lines={7} data-scroll={elem?.user?.readMore === false} 
                                   id={`rate-user-about-${elem?.user?.id}`} style={{maxHeight: 300}}>
                                    {(elem?.user?.about ?? '').trim()
                                    .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                                        <span className={`article`} key={index}>{elem_}</span> : null
                                    )})}
                                </p>
                                {elem?.user?.readMore && 
                                <button className={`btn mobile`} onClick={e => {
                                    e.stopPropagation();
                                    setToRateProfiles((val: any) => SetDict(
                                        val, [index, "user", "readMore"], 
                                        false,
                                    ))
                                }}>
                                    <p data-size={8} className={`semibold seminarrow pt`}>
                                        {`Read more`}
                                    </p>
                                </button>}
                            </div>
                        </div>)}
                    </div>

                    <div className={`grid-1-1 w-flex`} style={{gap: 8}}>
                        <button className={`block btn-flex desktop row center`} data-color={`light-gray`} 
                                onClick={() => { 
                                    ToRateSkip();
                                    setRateFlow(undefined);
                                    setToRateProfiles(null);
                                }}>
                            <p>{`Skip`}</p>
                        </button>
                        <button className={`block btn-flex desktop row center`} data-color={`black`} 
                                onClick={() => { 
                                    RelevanceRequest(
                                        toRateProfiles[toRateRatedNum * 2 + 1 - (toRateSelectedNum ?? 0)]?.user?.id, 
                                        [`${toRateProfiles[toRateRatedNum * 2 + 1 - (toRateSelectedNum ?? 0)]?.user?.id} worse than ${toRateProfiles[toRateRatedNum * 2 + (toRateSelectedNum ?? 0)]?.user?.id}`], 
                                        false,
                                    )
                                    if (toRateRatedNum * 2 + 4 > toRateProfiles?.length) {
                                        setRateFlow(undefined);
                                        setToRateProfiles(null);
                                    } else {
                                        setToRateRatedNum(val => val + 1);
                                        setToRateSelectedNum(undefined);
                                    }
                                }}
                                disabled={toRateSelectedNum === undefined}>
                            <p>{`Next`}</p>
                        </button>
                    </div>
                    </>}
                </div> : (context.profile?.subscription?.is_subscription_active === false && <BecomeAMemberBlock/>))}

                {context.profile?.subscription?.is_subscription_active &&
                <div className={`block b-3 w-flex column p-3`}
                    style={{minHeight: !match ? 630 : 500}}>
                    {context.profile?.is_ready ? <>
                    <div></div>
                    <div className={`column top`}>
                        <CardMatchesVideo className={`mb-3`}/>
                        <p data-size={8} className={`center mb-3`} data-color={`black-40-opacity`}>
                            {`Your next match is in ${UntilMatch()?.text}.`}<br/>
                            {`We'll notify you by email.`}
                        </p>
                        <div className={`row center nowrap`}>
                            <button className={`block btn-flex desktop row center`} data-color={`light-gray`}
                                    style={{width: 191}} onClick={() => {
                                        setWaitReadyStatus(true);
                                        SetReadyStatus(false, {
                                            0: () => { setWaitReadyStatus(false) },
                                            200: () => { 
                                                context.setProfile((val: any) => SetDict(val, ["is_ready"], false));
                                            },
                                        })
                                    }}>
                                <p>{`Pause matching`}</p>
                                {waitReadyStatus &&
                                <Loading width={24} height={24} color={`black`}/>}
                            </button>
                            <button className={`block btn-flex desktop row center`} data-color={`black`}
                                    style={{width: 191}} onClick={() => {
                                        context.setPage(1, 1);
                                        context.setTab(1, 1);
                                    }}>
                                <p>{`Go to feed`}</p>
                            </button>
                        </div>
                    </div>
                    <p data-size={7} className={`center`} data-color={`black-40-opacity`}>
                        {`If you can’t find our email, check your spam folder.`}<br/>
                        {`And please mark `}
                        <span data-color={`blue`}>
                            {`noreply@osmos.social`}
                        </span>
                        {` as ‘not\u00A0spam’ `}
                    </p>
                    </> : <>
                    <div></div>
                    <div className={`column top`}>
                        <p style={{fontSize: 32}} className={`semibold seminarrow max-w-9 center mb-1`}>
                            {`Are you ready for 1:1\u00A0conversation?`}
                        </p>
                        <p data-size={8} data-color={`black-40-opacity`}>
                            {`Let us know by 23:59 Sunday`}
                        </p>
                        <button className={`block btn-flex desktop row center mt-3`} data-color={`black`}
                                style={{width: 191}} onClick={() => {
                                    setWaitReadyStatus(true);
                                    SetReadyStatus(true, {
                                        0: () => { setWaitReadyStatus(false) },
                                        200: () => { 
                                            context.setProfile((val: any) => SetDict(val, ["is_ready"], true));
                                        },
                                    })
                                }}>
                            <p>{`Yes, I'm in`}</p>
                            {waitReadyStatus &&
                            <Loading width={24} height={24} color={`white`}/>}
                        </button>
                    </div>
                    <div></div>
                    </>}
                </div>}
                </> :
                <div className={`w-flex b-3 h-chat skeleton`}></div>}
            </div>

            {!!feedBackPopup &&
            <GradePopupCenter show={!!feedBackPopup} match={feedBackPopup} onLeaveFeedback={() => { setMatch(null) }}
                setShow={(show: boolean) => { setFeedBackPopup((val: any) => show ? val : undefined) }}/>}
        </div>
    )
}

export const ProfileChatsDesktop = (props: any) => { 
    const navigate = useNavigate();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ cookie ] = useCookies(['user_id_cookie']);
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    // const [ notificationNumber, setNotificationNumber ] = useState(0)

    const [ match_, setMatch_ ] = useState<any | null>(null);
    const [ chatId, setChatId ] = useState<string | null>();
    const [ offerId, setOfferId ] = useState<number | null>(Number(searchParams.get(`offer_id`) ?? null));
    const [ requestedId, setRequestedId ] = useState<number | null>(Number(searchParams.get(`requested_id`) ?? null));

    const [ requested, setRequested ] = useState<any>();
    const [ offers, setOffers ] = useState<any>();
    const [ chats, setChats ] = useState<any>();

    const [ ws, setWs ] = useState<WebSocket | null>(null);
    const [ receivedMessage, setReceivedMessage ] = useState<any>({});

    useEffect(() => {
        if (cookie['user_id_cookie']) {
            // ChatsRequest(cookie['user_id_cookie'], props.setChats);
        } else {
            navigate(`/?redirect=${encodeURIComponent(`/profile/chat`)}`);
        }      
    }, [])

    useEffect(() => {
        if (chats) {
            // setNotificationNumber(chats.reduce((init: number, chat: any) => (init + (chat?.unread ?? 0)), 0));
            
            const old_chats = [...chats].sort(
                (a, b) => DateToTimestamp(b.created_at) - DateToTimestamp(a.created_at)
            );

            if (old_chats?.length > 0) {
                ProfileRequest(
                    old_chats[0]?.user_id, 
                    setMatch_, 
                    () => { setLoaded(true) }
                );
            } else {
                setLoaded(true);
            } 
        }
    }, [chats])

    useEffect(() => {
        if (ws) {
            ws.onmessage = (e: MessageEvent) => {                              
                const message = JSON.parse(e.data);
                setReceivedMessage(message);  
            }
            ws.onclose = (e: CloseEvent) => {
                setTimeout(() => {
                    setWs(new WebSocket(`${GetWebsocketUrl()}s`));
                }, 1000)
            }
        }
    }, [ws]) 

    useEffect(() => {
        if (match_) {
            setLoaded(true);
        }
    }, [match_]) 

    useEffect(() => {
        if (receivedMessage['event_type'] === 'send') {     
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']);                        
            props.setChats((val: any) => SetDict(val, [idx, 'last_message'], receivedMessage[`message`]));                   
            props.setChats((val: any) => SetDict(
                val, 
                [idx, 'unread'], 
                val[idx].unread + (receivedMessage['from_client'] === cookie[`user_id_cookie`] ? 0 : 1)
            ));
        } 
        if (receivedMessage['event_type'] === 'edit') {                              
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']);  
            if (receivedMessage[`message_id`] === chats[idx][`last_message`]) {                      
                props.setChats((val: any) => SetDict(val, [idx, 'last_message'], receivedMessage[`message`]));
            }
        } 
        if (receivedMessage['event_type'] === 'read') {                              
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']); 
            if (cookie[`user_id_cookie`] === receivedMessage[`from_client`]) { 
                props.setChats((val: any) => SetDict(val, [idx, 'unread'], 0));
            }
        }
        if (receivedMessage['event_type'] === 'delete') { 
            const idx = chats.findIndex((elem: any) => elem?.id === receivedMessage['chat_id']);
            if (receivedMessage[`message_id`] === chats[idx][`last_message`][`id`]) {  
                ChatsRequest(cookie['user_id_cookie'], props.setChats);
            }
        }         
    }, [receivedMessage])

    useEffect(() => {
        if (ws) {
            ws.onclose = null;
            ws.onmessage = null;
            ws?.close();
        }
        setWs(new WebSocket(`${GetWebsocketUrl()}s`));
    }, [chats?.length ?? 0])

    useEffect(() => {
        ChatsRequest(cookie[`user_id_cookie`], props.setChats);
        OffersRequest(cookie[`user_id_cookie`], props.setOffers, props.setRequested);  

        return () => {
            if (ws) {
                ws.onclose = null;
                ws.onmessage = null;
                ws?.close();
            }

            context.setNewChatOffer(undefined);
            props.setRequested((val: any[]) => val ? val.filter(elem => !elem.is_new_requested) : val);
        }
    }, [])

    useEffect(() => {     
        if (context.newChatOffer) {
            const chatIdx = (props.chats ?? []).findIndex((val: any) => val?.user_id === context.newChatOffer?.pair?.id);
            const requestedIdx = (props.requested ?? []).findIndex((val: any) => val?.pair?.id === context.newChatOffer?.pair?.id);
            const offerIdx = (props.offers ?? []).findIndex((val: any) => val?.user?.id === context.newChatOffer?.pair?.id);
            if (chatIdx > -1) {
                setRequested(props.requested);
                setOffers(props.offers);
                setChats(SetDict(props.chats, [chatIdx, "refer_to_request"], context.newChatOffer?.refer_to_request));
                setChatId(props.chats[chatIdx]?.id);               
            } else if (requestedIdx > -1) {
                setChats(props.chats);
                setRequested(SetDict(props.requested, [requestedIdx, "refer_to_request"], context.newChatOffer?.refer_to_request));
                setOffers(props.offers);
                setRequestedId(requestedIdx);
            } else if (offerIdx > -1) {
                setChats(props.chats);
                setRequested(props.requested);
                setOffers(SetDict(props.offers, [offerIdx, "refer_to_request"], context.newChatOffer?.refer_to_request))
                setOfferId(offerIdx);
            } else {
                setChats(props.chats);
                setOffers(props.offers);
                setRequested([context.newChatOffer].concat(props.requested ?? []));
                setRequestedId(0);
            }
        } else {
            setChats(props.chats);
            setRequested(props.requested);
            setOffers(props.offers);
        }
    }, [props.chats, props.requested, props.offers, context.newChatOffer])

    useEffect(() => {
        setChatId(null);
        setOfferId(null);
        setRequestedId(null);
    }, [context.tab])

    useEffect(() => {
        if (chatId && globalContext.isTablet) {
            navigate(`/messenger/${chatId}`)
        }

        if (chatId) {
            setRequestedId(null)
            setOfferId(null)
        }
    }, [chatId])

    useEffect(() => {
        if (typeof requestedId === 'number') {
            setChatId(null)
            setOfferId(null)
        }
    }, [requestedId])

    useEffect(() => {
        if (typeof offerId === 'number') {
            setChatId(null)
            setRequestedId(null)
        }
    }, [offerId])

    useEffect(() => {
        if (!chatId && searchParams.get("chat_id") && chats?.length) {
            if (
                !chats.find((val: any) => val?.id === searchParams.get("chat_id")) &&
                chats.find((val: any) => val?.user_id === searchParams.get("chat_id"))
            ) {
                setChatId(chats.find((val: any) => val?.user_id === searchParams.get("chat_id"))?.id ?? null)
                setSearchParams({}); 
            } else if (
                chats.find((val: any) => val?.id === searchParams.get("chat_id"))
            ) {
                setChatId(searchParams.get("chat_id") ?? null)
                setSearchParams({}); 
            }
        }
    }, [chats?.length])
    
    return(
        <div className={`w-flex mb-5 column top`}> 
            {!(context.tab - 0) && ((
                chats && chats?.length === 0 &&
                requested && requested?.length === 0 &&
                offers && offers?.length === 0 
            ) ?            
            <div className={`w-flex column`}>
                <MessageCardDesktop profile={props.profile} type={`chat`} className={`w-10 sm-w-flex`}/>
            </div> :
            <div className={`w-flex md-w-10 sm-w-flex row gap-3 grid-2-5 md-grid-1 top px-6 md-p-0`}>
                <div>
                    {loaded ?
                    <ChatListDesktop chats={chats} setChats={props.setChats} 
                                     chatId={chatId} setChatId={setChatId} 
                                     offerId={offerId} setOfferId={setOfferId}
                                     requestedId={requestedId} setRequestedId={setRequestedId}
                                     chatsRequested={requested} setChatsRequested={setRequested} 
                                     chatsOffers={offers} setChatsOffers={props.setOffers}/> : 
                    <div className={`skeleton w-flex h-chat b-3`}></div>}
                </div>
                <div data-md-hidden={(requestedId === null && offerId === null && chatId === null) ? true : null}>
                    {loaded ? ((requestedId === null && offerId === null) ?
                    <ChatFieldDesktop chats={chats} setChats={props.setChats} chatId={chatId} setChatId={setChatId} className={`pb-4`}/> : 
                    (requestedId !== null ?
                    <ChatFieldDesktop chats={requested} setChats={props.setChats} chatId={requestedId} setRequested={setRequested}
                                      setChatId={setChatId} setRequestedId={setRequestedId} className={`pb-4`} requested={true}/> :
                    <ChatFieldDesktop chats={offers} setChats={props.setChats} chatId={offerId} setOffers={props.setOffers}
                                      setChatId={setChatId} setOfferId={setOfferId} className={`pb-4`} offers={true}/>)) : 
                    <div className={`skeleton w-flex h-chat b-3 mb-4`}></div>}
                </div>
            </div>)}

            {!(context.tab - 1) && ((offers && offers?.length === 0) ?
            <div className={`pl-4 md-pl-3 w-flex`}>
                <MessageCardDesktop profile={props.profile} type={`offer`}/>
            </div> :
            <div className={`w-flex row nogap left top`}>
                <div className={`pl-4 md-pl-3 md-col-12 col-4`}>
                    {loaded ?
                    <OfferListDesktop offers={offers} setOffers={props.setOffers} offerId={offerId} setOfferId={setOfferId} className={`pb-4`}/> : 
                    <div className={`skeleton w-flex h-chat b-3 mb-3`}></div>}
                </div>
                <div className={`pl-4 md-pl-3 md-col-12 col-8`} data-md-hidden={offerId === null ? true : null}>
                    {loaded ?
                    <ChatFieldDesktop chats={offers} setChats={props.setChats} chatId={offerId} setOffers={props.setOffers}
                                      setChatId={setChatId} setOfferId={setOfferId} className={`pb-4`} offers={true}/> : 
                    <div className={`skeleton w-flex h-chat b-3 mb-3`}></div>}
                </div>
            </div>)}

            {!(context.tab - 2) && ((requested && requested?.length === 0) ?
            <div className={`pl-4 md-pl-3 w-flex`}>
                <MessageCardDesktop profile={props.profile} type={`requested`}/>
            </div> :
            <div className={`w-flex row nogap left top`}>
                <div className={`pl-4 md-pl-3 md-col-12 col-4`}>
                    {loaded ?
                    <OfferListDesktop offers={requested} setOffers={setRequested} offerId={requestedId} setOfferId={setRequestedId} className={`pb-4`} requested={true}/> : 
                    <div className={`skeleton w-flex b-3 mb-3`} style={{height: 600}}></div>}
                </div>
                <div className={`pl-4 md-pl-3 md-col-12 col-8`} data-md-hidden={requestedId === null ? true : null}>
                    {loaded ?
                    <ChatFieldDesktop chats={requested} setChats={props.setChats} chatId={requestedId} setRequested={setRequested}
                                    setChatId={setChatId} setRequestedId={setRequestedId} className={`pb-4`} requested={true}/> : 
                    <div className={`skeleton w-flex b-3 mb-3`} style={{height: 600}}></div>}
                </div>
            </div>)}
        </div>
    )
}

export const ProfilePageDesktop = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ subscriptions, setSubscriptions ] = useState<{
        followers: any[],
        follow: any[],
    }>();
    const [ search, setSearch ] = useState<string>('');
 
    const [ rndColor, setRndColor ] = useState<string[]>([]);
    const [ waitStripe, setWaitStripe ] = useState<boolean>(false);

    const [ tab, setTab ] = useState<number>(0);

    const projectScrollBlock = useRef<HTMLDivElement | null>(null);
    const projectScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftProjectBtn, setShowLeftProjectBtn ] = useState<boolean>(false);
    const [ showRightProjectBtn, setShowRightProjectBtn ] = useState<boolean>(false);

    const recommendScrollBlock = useRef<HTMLDivElement | null>(null);
    const recommendScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftRecommendBtn, setShowLeftRecommendBtn ] = useState<boolean>(false);
    const [ showRightRecommendBtn, setShowRightRecommendBtn ] = useState<boolean>(false);

    const [ canHelpPopup, setCanHelpPopup ] = useState<boolean>(false);
    const [ canHelpRequest, setCanHelpRequest ] = useState<any>();

    const [ email, setEmail ] = useState<string>("");    
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);
    const [ mailOkPopup, setMailOkPopup ] = useState<boolean>(false);
    const [ mailErrorPopup, setMailErrorPopup ] = useState<boolean>(false); 

    const FilterList = (list: any[], search: string = '') => {
        return list.filter(val => (
            (val.fname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.lname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.name ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.occupation ?? '').toLowerCase().includes(search.toLowerCase())
        ))
    }

    useEffect(() => {
        setRndColor(
            [[`gray`, `black`, `black`, `#F5F5F5`], ...ScreenColors]
            [Math.floor(Math.random() * 4)]);
    }, [context.profile?.projects ?? null])

    useEffect(() => {
        if (context.profile?.id) {
            SubscriptionsRequest(
                context.profile?.id,
                setSubscriptions,
            )
        }
    }, [context.profile?.id])
    
    useEffect(() => {
        setSearch(``);
    }, [tab])

    useEffect(() => {
        if (rndColor) {
            const btn = document.querySelector(`#footer-button-color`) as HTMLButtonElement;
            // console.warn(`profile changed`)
            if (btn && false) {
                btn.setAttribute(`footer-color`, rndColor[0] ?? ``);
                btn.click();
            }
            // document.body.style.backgroundColor = rndColor[3];
        }
    }, [rndColor])

    useEffect(() => {
        return () => {            
            (document.querySelector(`body`) as HTMLBodyElement).style.backgroundColor = ``;
        }
    }, [])

    return(<div className={`w-flex column top`}>
        <div className={`row w-flex md-w-10 sm-w-flex gap-3 grid-2-5 md-grid-1 top mb-5 px-6 md-px-0`}>
            <div hidden={context.profile?.hideUser}>
            {(props.loaded && context.profile) ? <> 
                {!props.other && (!context.isSubscriptionActive ? 
                <div className={`block b-3 p-2 w-flex mb-3`} data-color={`coral`}>
                    <p data-size={8} className={`seminarrow semibold mb center`}>
                        {`Your subscription ${context.profile?.subscription?.is_stripe_customer ? "has expired" : "is not active"}`}
                    </p>
                    <p data-size={6} className={`mb-2 center`}>
                        {`We hope we welcome you back!`}
                    </p>

                    <button className={`block btn-rectangle-40 desktop w-flex row center`} onClick={() => { 
                        setWaitStripe(true);
                        OpenStripePortal(
                            context?.profile?.subscription?.is_stripe_customer, 
                            () => { setWaitStripe(false) },
                            undefined, 
                            context.profile?.subscription?.is_subscription_active ? '2m' : undefined
                        ) 
                    }}>
                        {waitStripe ? <Loading width={24} height={24} color={`coral`}/> :
                        <p data-color={`coral`}>{`Activate membership`}</p>}
                    </button>
                </div> : (context.profile?.subscription?.is_subscription_paused && Until(context.profile?.subscription?.expire_at)?.days < 8 &&
                <div className={`block b-3 p-2 w-flex mb-3`} data-color={`coral`}>
                    <p data-size={8} className={`seminarrow semibold mb center`}>
                        {`Your subscription expires in ${
                            Until(context.profile?.subscription?.expire_at)?.days === 7 ? `one week` : 
                            `${Until(context.profile?.subscription?.expire_at)?.days} day${Until(context.profile?.subscription?.expire_at)?.days > 1 ? "s" : ""}`
                        }`}
                    </p>
                    <p data-size={6} className={`mb-2 center`}>
                        {`We hope we welcome you back!`}
                    </p>

                    <button className={`block btn-rectangle-40 desktop w-flex row center`} onClick={() => { 
                        setWaitStripe(true);
                        OpenStripePortal(context?.profile?.subscription?.is_stripe_customer, () => { setWaitStripe(false) }) 
                    }}>
                        {waitStripe ? <Loading width={24} height={24} color={`coral`}/> :
                        <p data-color={`coral`}>{`Activate membership`}</p>}
                    </button>
                </div>
                ))}

                {/* FOR NARROW SCREEN */}
                <div className={`block p-3 b-3 w-flex row gap-2 left top nowrap`} onClick={() => {}} 
                     data-hidden={props.isNarrow ? null : true} data-md-shown={props.isNarrow ? null : true}>
                    <NewAvatar size={globalContext.isTablet ? 9 : 8} name={context.profile?.name} avatar={context.profile?.avatar?.link}
                               type={context.profile?.avatar?.style} style={{transitionDuration: `0s`}}/>
                    <div className={`left column`} style={{
                            minHeight: globalContext.isTablet ? 198 : 123,
                            width: `calc(100% - ${globalContext.isTablet ? 198 : 123}px)`,
                        }}>
                        <div>
                            {props.isNarrow ?
                            <a href={`/profile/${context.profile?.unique_id ?? context.profile?.id}`}>
                                <p data-size={4} className={`semibold`}>{context.profile?.name}</p>
                            </a> : <p data-size={4} className={`semibold`}>{context.profile?.name}</p>}
                            <p data-size={globalContext.isTablet ? 5 : 8} data-lines={2}>
                                {context.profile?.occupation}
                            </p>   
                        </div>

                        <div className={`mt-2 w-flex`}>
                            {props.other && (cookie['user_id_cookie'] ? 
                            <div className={`row nowrap w-flex`}> 
                                {!props.isNarrow ? <>
                                <button className={`block btn-circled-40 desktop`} data-color={`light-gray`} hidden={true}
                                        onClick={() => {
                                            if (!context.profile?.followed) {
                                                Subscribe(cookie['user_id_cookie'], context.profile?.id, true, () => {
                                                    SubscriptionsRequest(
                                                        context.profile?.id,
                                                        setSubscriptions,
                                                    )
                                                    context.setProfile((val: any) => SetDict(val, ['followed'], true));
                                                    if (typeof props.onSubscribe === `function`) {
                                                        props.onSubscribe();
                                                    }
                                                });
                                            } else {
                                                Subscribe(cookie['user_id_cookie'], context.profile.id, false, () => {
                                                    SubscriptionsRequest(
                                                        context.profile?.id,
                                                        setSubscriptions,
                                                    )
                                                    context.setProfile((val: any) => SetDict(val, ['followed'], false));
                                                });
                                                if (typeof props.onSubscribe === `function`) {
                                                    props.onSubscribe();
                                                }
                                            }
                                        }} id={`${context.profile?.followed ? `unfollow` : `follow`}-user-btn`}
                                        disabled={!context.isSubscriptionActive}>
                                    <p className={`center`}>{context.profile?.followed ? `Unfollow` : `Follow`}</p>
                                </button>
                                {context.profile?.is_matched ?
                                <a className={`block btn-circled-40 desktop row`} data-color={`green`}
                                    href={`/profile/chat?chat_id=${context.profile?.id}`} target={`_blank`}>
                                    <p>{`Arrange a meeting`}</p>
                                </a> :
                                <button className={`block btn-circled-40 desktop row`} data-color={`green`}
                                        onClick={() => {
                                            // context.setWishToHelpRequest1({user_id: context.profile?.id});
                                            // context.setWishToHelpPopup1(true);
                                            sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                                created_at: Date.now() / 1000,    
                                                items: [],
                                                pair: context.profile,
                                                user: context.ownerProfile,
                                                is_new_requested: true, 
                                                is_chat_offer: true,
                                            }));
                                            navigate(`/profile/chat?new_chat_offer=true`);
                                        }} hidden={true} disabled={!context.isSubscriptionActive}>
                                    <p>{`Arrange a meeting`}</p>
                                </button>}</> : <>
                                <a className={`block btn-circled-40 desktop mt-2`} data-color={`light-gray`}
                                href={`/profile/${context.profile?.unique_id ?? context.profile?.id}`} target={`_blank`}>
                                    <p className={`center`}>{`Full profile`}</p>
                                </a>
                                <button className={`block btn-circled-40 desktop p-left p-right mt-2 row`} data-color={`black`}
                                        onClick={() => { 
                                            if (typeof props.onStartChat === `function`) {
                                                props.onStartChat();
                                            } else if (context.profile?.is_matched) {
                                                context.setPage(2);
                                                setSearchParams({chat_id: context.profile?.id});
                                            } else {
                                                context.setWishToHelpRequest1({user_id: context.profile?.id});
                                                context.setWishToHelpPopup1(true);
                                            }
                                        }}>
                                    <img src={ChatWhite} alt={``} className={`w-3 mx`}></img>
                                </button></>}
                            </div> : 
                            <div className={`row w-flex nowrap`}>
                                <a className={`block btn-circled-32 desktop row center w-flex`} href={`/`} data-color={`light-gray`}>
                                    <p>{`Sign up`}</p>
                                </a>
                                <button className={`block btn-circled-32 desktop p-left p-right row center`} data-color={`light-gray`}
                                        onClick={() => {
                                            GoogleAuth(
                                                searchParams.get('code'), 
                                                searchParams.get('community'), 
                                                encodeURIComponent(`/profile/${context.profile?.id}`), 
                                                null,
                                                {
                                                    203: () => {
                                                        navigate('/notfound');
                                                    }
                                                },
                                            )
                                        }}>
                                    <img src={GoogleLogo} alt={``} className={`w-3`}></img>
                                </button>
                            </div>)}
                        </div>
                    </div>
                </div> 
                {/* FOR WIDE SCREEN */}
                <div className={`block p-2 b-3 w-flex`} data-md-hidden={true} 
                     onClick={() => {}} hidden={props.isNarrow}>
                    <NewAvatar size={0} name={context.profile?.name} avatar={context.profile?.avatar?.link}
                                type={context.profile?.avatar?.style} className={`circled mb-2`}/>
                    <p data-size={4} className={`mb semibold`}>{context.profile?.name}</p>
                    <p data-size={5}>
                        {context.profile?.occupation}
                    </p>
                    {props.other && (cookie['user_id_cookie'] ?
                    <div className={`row nowrap w-flex mt-2`}>
                        <button className={`block btn-circled-40 desktop`} data-color={`light-gray`}
                                onClick={() => {
                                    if (!context.profile?.followed) {
                                        Subscribe(cookie['user_id_cookie'], context.profile?.id, true, () => {
                                            SubscriptionsRequest(
                                                context.profile?.id,
                                                setSubscriptions,
                                            )
                                            context.setProfile((val: any) => SetDict(val, ['followed'], true));
                                            if (typeof props.onSubscribe === `function`) {
                                                props.onSubscribe();
                                            }
                                        });
                                    } else {
                                        Subscribe(cookie['user_id_cookie'], context.profile.id, false, () => {
                                            SubscriptionsRequest(
                                                context.profile?.id,
                                                setSubscriptions,
                                            )
                                            context.setProfile((val: any) => SetDict(val, ['followed'], false));
                                            if (typeof props.onSubscribe === `function`) {
                                                props.onSubscribe();
                                            }
                                        });
                                    }
                                }} id={`${context.profile?.followed ? `unfollow` : `follow`}-user-btn`}
                                disabled={!context.isSubscriptionActive} hidden={true}>
                            <p>{context.profile?.followed ? `Unfollow` : `Follow`}</p>
                        </button> 
                        {context.profile?.is_matched ?
                        <a className={`block btn-flex desktop w-flex row center`} data-color={`green`}
                           href={`/profile/chat?chat_id=${context.profile?.id}`} target={`_blank`}>
                            <p>{`Arrange a meeting`}</p>
                        </a> : 
                        <button className={`block btn-flex desktop w-flex row center`} data-color={`green`}
                                onClick={() => {
                                    // context.setWishToHelpRequest1({user_id: context.profile?.id});
                                    // context.setWishToHelpPopup1(true);
                                    sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                        created_at: Date.now() / 1000,    
                                        items: [],
                                        pair: context.profile,
                                        user: context.ownerProfile,
                                        is_new_requested: true, 
                                        is_chat_offer: true,
                                    }));
                                    navigate(`/profile/chat?new_chat_offer=true`);
                                }} hidden={true} disabled={!context.isSubscriptionActive}>
                            <p>{`Arrange a meeting`}</p>
                        </button>}
                    </div> :
                    <div className={`row w-flex nowrap mt-2`} hidden={true}>
                        <a className={`block btn-circled-40 desktop row center w-flex`} href={`/`} data-color={`light-gray`}>
                            <p>{`Sign up`}</p>
                        </a>
                        <button className={`block btn-circled-40 desktop p-left p-right row center`} data-color={`light-gray`}
                                onClick={() => {
                                    GoogleAuth(
                                        searchParams.get('code'), 
                                        searchParams.get('community'), 
                                        encodeURIComponent(`/profile/${context.profile?.id}`), 
                                        null,
                                        {
                                            203: () => {
                                                navigate('/notfound');
                                            }
                                        },
                                    )
                                }}>
                            <img src={GoogleLogo} alt={``} className={`w-3`}></img>
                        </button>
                    </div>)}
                </div>

                {!props.other &&
                <div className={`row md-nowrap mt-3`}>
                    <button className={`block btn-block-new b-1 desktop w-flex row gap-2`} onClick={() => { context.setTab(3) }}>
                        <p>{`Edit profile`}</p>
                        <Edit color={`black`} className={`w-3 h-3`}/>  
                    </button>
                    <button className={`block btn-block-new b-1 desktop w-flex row nowrap`} onClick={() => { 
                        // props.setShowSettingsPopup(true);
                        context.setTab(4); 
                    }}>
                        <p>{`Account settings`}</p>
                        <img src={GearBlack} alt={``} className={`h-3`}></img>
                    </button>
                </div>}

                {!cookie[`user_id_cookie`] &&                    
                <div className={`block w-flex p-2 b-3 mt-3`} data-md-hidden={true}>
                    <p data-size={4} className={`center semibold seminarrow mb-3`}>
                        {`Sign in`}
                    </p>
                    {[
                        {title: `Google`, logo: GoogleLogo, onClick: () => {
                            GoogleAuth(
                                null, 
                                null, 
                                encodeURIComponent(`/profile/${context.profile?.id}`), 
                                null,
                                {
                                    203: () => {
                                        navigate('/notfound');
                                    }
                                },
                            )
                        }, hidden: false},
                        {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                            LinkedInAuth(
                                null, 
                                null, 
                                encodeURIComponent(`/profile/${context.profile?.id}`), 
                                null,
                                {
                                    203: () => {
                                        navigate('/notfound');
                                    }
                                },
                            )
                        }, hidden: true},
                    ].map((elem, index) => {return (
                        <button className={`btn-google mobile w-flex row center mb-1`}
                                data-color={`black`} onClick={() => {
                                    elem.onClick();
                                }} key={index} hidden={elem.hidden}>
                            <img src={elem.logo} alt={`li`}></img>
                            <p>{`Continue with ${elem.title}`}</p>
                        </button>
                    )})}                
                    <div className={`w-flex row my-2 nowrap`}>
                        <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                        <p data-size={8} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                            {`or`}
                        </p>
                        <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                    </div>
                    <form className={`w-flex`} onSubmit={e => {
                        e.preventDefault();
                        if (email) {
                            setWaitEmail(true); 
                            setTimeout(() => { setWaitEmail(false) }, 5000);
                            EmailAuth(
                                email, () => {}, () => {},
                                null, 
                                null, 
                                encodeURIComponent(`/profile/${context.profile?.id}`), 
                                null,
                                {
                                    200: () => {                                     
                                        setWaitEmail(false);
                                        setMailOkPopup(true);
                                    },
                                    0: () => {
                                        setMailErrorPopup(true);
                                    },
                                    203: () => {
                                        navigate('/notfound');
                                    }
                                }, 
                            )
                        } 
                    }}>
                        <div className={`row mobile input-block mb-1`}>
                            <input className={`mobile`} placeholder={`Email address`} type={`email`}
                                    value={email} onChange={e => {
                                        setEmail(e.target.value);
                                    }} required={true} data-size={6}></input> 
                                <p className={`text-4 semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                        </div>
                            
                        <button className={`block btn-flex mobile w-flex row center`}
                                data-color={`black`} type={`submit`}>
                            {waitEmail ? 
                            <Loading color={`white`} width={24} height={24}/> : 
                            <p>{`Continue`}</p>}
                        </button>
                    </form>
                    <div className={`mt-3 row center nogap nowrap`}>
                        <p data-size={8} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                            {`New to Osmos?`}&nbsp;	
                            <a href={`/`}>
                                <span data-color={`black`}>{`Join now`}</span>
                            </a>
                        </p>
                    </div>
                </div>}

                {context.profile?.links && context.isSubscriptionActive &&
                <div className={`block p-2 b-3 w-flex row grid-1 md-grid-1-1 gap-1 mt-3`}>
                    {context.profile?.links?.email &&
                    <button className={`w-flex row top nowrap`}
                         onClick={() => {
                            const newWindow: WindowProxy | null = window.open(`mailto:${context.profile?.links?.email}`, '_blank');
                            if (newWindow) {
                                newWindow.focus();
                            }
                         }}>
                        <div className={`block w-3 h-3 b centered noshrink`} data-color={`black`}>
                            <p data-size={5} className={`semibold narrow`} style={{marginTop: `-0.2em`}}>
                                {`@`}
                            </p>
                        </div>
                        <div className="row nogap" style={{width: `calc(100% - 28px)`}}>
                            <p className={`ellipsis semibold seminarrow`} data-size={8} style={{lineHeight: `24px`}}>
                                {context.profile?.links?.email.split('@')[0]}
                                {`@${context.profile?.links?.email.split('@')[1]}`}
                            </p>
                            {/* <p data-size={8} style={{lineHeight: `24px`}}>{`@${context.profile?.links?.email.split('@')[1]}`}</p> */}
                        </div>
                    </button>}
                    {[
                        {title: 'LinkedIn', logo: LinkedInWhite, link: true},
                        {title: 'WhatsApp', logo: PhoneWhite},
                        {title: 'iMessage', logo: AppleWhite},
                        {title: 'Facebook', logo: FacebookWhite, link: true},
                        {title: 'Telegram', logo: TelegramWhite, link: 'https://t.me/'},
                        {title: 'X', logo: XWhite, link: true},
                    ].map((elem, index) => {return (
                        context.profile?.links[elem.title.toLowerCase()] ?
                        <div className={`w-flex row top nowrap`} key={index}> 
                            <div className={`block w-3 h-3 b centered noshrink`} data-color={`black`}>                           
                                <img src={elem.logo} alt={`ph`} style={{width: 20}}></img>
                            </div>
                            {elem.link ? 
                            <a data-size={8} className={`ellipsis`} style={{wordBreak: 'break-word', lineHeight: `24px`, width: `calc(100% - 32px)`}} 
                               target={`_blank`}
                               href={MakeValidURL(`${typeof elem.link === `string` ? elem.link : ''}${context.profile?.links[elem.title.toLowerCase()].replace(`@`, '')}`)}>
                                <span data-color={`black`} className={`semibold seminarrow`}>
                                    {context.profile?.links[elem.title.toLowerCase()]}
                                </span>
                            </a> :
                            <p data-size={8} className={`ellipsis semibold seminarrow`} style={{wordBreak: 'break-word', lineHeight: `24px`, width: `calc(100% - 32px)`}}>
                                {context.profile?.links[elem.title.toLowerCase()]}
                            </p>}
                        </div> : null
                    )})}
                </div>}
            </> : <>
                <div className={`skeleton b-3 w-flex h-8 mb-3`}></div>
                <div className={`skeleton b-3 w-flex h-5 mb-1`}></div>
                <div className={`skeleton b-3 w-flex h-5 mb-3`}></div>
            </>}
            </div>
            
            <div className={`h-flex column top`}>
            {props.loaded ? <>
                <div className={`row w-flex nowrap mb-3 noshrink`} hidden={true || !cookie[`user_id_cookie`]}>
                    <div className={`row left nowrap noshrink md-w-flex`}>
                        <button className={`block btn-tab-new desktop row`} data-color={tab === 0 ? `black` : `white`}
                                onClick={() => { setTab(0) }}>
                            <p>{`About`}</p>
                        </button>
                        <button className={`block btn-tab-new desktop row`} data-color={tab === 2 ? `black` : `white`}
                                onClick={() => { setTab(2) }} hidden={props.other && !subscriptions?.followers?.length} 
                                disabled={!subscriptions?.followers?.length}>
                            <p>{`Followers`}</p>
                            {!!subscriptions?.followers?.length &&
                            <div className={`block min-w-3 h-3 b-2 centered not-active`} data-color={`blue`}>
                                <p data-size={6} className={`not-active`}>{subscriptions?.followers?.length}</p>
                            </div>}
                        </button>
                        <button className={`block btn-tab-new desktop row`} data-color={tab === 3 ? `black` : `white`}
                                onClick={() => { setTab(3) }} hidden={props.other && !subscriptions?.follow?.length} 
                                disabled={!subscriptions?.follow?.length}>
                            <p>{`Following`}</p>
                            {!!subscriptions?.follow?.length &&
                            <div className={`block min-w-3 h-3 b-2 centered not-active`} data-color={`blue`}>
                                <p data-size={6} className={`not-active`}>{subscriptions?.follow?.length}</p>
                            </div>}
                        </button>
                    </div>
                </div>

                {tab === 0 && <>
                {(context.profile?.industry?.length > 0 || !!context.profile?.about) &&
                <div className={`block p-3 b-3 w-flex`}>                    
                    {context.profile?.industry?.length > 0 &&
                    <div className={`row w-flex nowrap mb-1`}>
                        <div className={`my row left w-flex h-3`} style={{overflow: `hidden`}}>
                            {context.profile?.industry.map((elem: string, index: number) => {return (
                                <p data-size={8} style={{color: CreateHSLColor(elem)}} 
                                   className={`semibold seminarrow`} key={index}>{elem}</p>
                            )})}
                        </div>                        
                        <div className={`row left nowrap nogap`} hidden={!context.profile?.location?.city}>
                            <img src={LocationGray} alt={``} className={`w-3`}></img>
                            <p data-size={8} data-color={`black-40-opacity`} className={`w-flex semibold seminarrow ellipsis`}>
                                {context.profile?.location?.city}
                            </p>
                        </div>
                    </div>} 
                    <p data-size={8} data-lines={true} className={`mb-1`}>
                        {(context.profile?.about ?? '').trim()
                        .split(`\n`).map((elem_: string, index: number) => {return (elem_ ?
                            <span className={`article`} key={index}>{elem_}</span> : null
                        )})}
                    </p>
                    <div className={`neg-mb-1`}></div>
                </div>}

                {!!OnlyValid(context.profile?.requests)?.length &&
                <div className={`block p-2 b-3 w-flex mt-3`}>
                    <div className={`row nowrap w-flex px-1 pt-1 mb-2`}>
                        <div className={`row left nowrap`}>
                            <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                <p data-size={6} className={`px-1`}>{OnlyValid(context.profile?.requests)?.length}</p>
                            </div>
                            <p data-size={8} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Requests`}</p>
                        </div>
                        {!props.other &&
                        <button className={`btn-symbol w-3 h-3 centered`} data-color={rndColor[2]}
                                onClick={() => { context.setAddRequestPopup(true) }}>
                            <img src={rndColor[2] === 'white' ? PlusWhite : PlusBlack} alt={`+`}></img>
                        </button>}
                    </div>
                    {OnlyValid(context.profile?.requests).map((elem: any, index: number) => {return (
                    <ProfileRequestCardDesktop request={elem} onCanHelp={() => {
                        setCanHelpRequest({
                            ...props.request,                
                            onSend: () => {  }
                        });
                        setCanHelpPopup(true);
                    }} key={index}/>)})}
                    <div className={`neg-mb-1`}></div>
                </div>}

                {!!context.profile?.projects?.length && 
                <div className={`block p-2 b-3 w-flex mt-3`} ref={projectScrollBlock}
                        onMouseEnter={() => {
                        if (projectScrollElem.current && 
                            projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                            if (IsLeftScroll(projectScrollElem.current)) {
                                setShowLeftProjectBtn(true);
                            }
                            if (IsRightScroll(projectScrollElem.current)) {
                                setShowRightProjectBtn(true);
                            }
                        }
                        }} onMouseLeave={() => { 
                        setShowLeftProjectBtn(false); 
                        setShowRightProjectBtn(false);
                    }}>
                    <div className={`row nowrap pt-1 px-1`}>
                        <div className={`row left nowrap`}>
                            <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                <p data-size={6} className={`px-1`}>{context.profile?.projects?.length}</p>
                            </div>
                            <p data-size={5} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Projects`}</p>
                        </div>
                        {!props.other &&
                        <button className={`btn-symbol w-3 h-3 centered`} data-color={rndColor[2]}
                                onClick={() => { context.setAddProjectPopup(true) }}>
                            <img src={rndColor[2] === 'white' ? PlusWhite : PlusBlack} alt={`+`}></img>
                        </button>}
                    </div>
                    <div className={`row nogap nowrap min-w-flex neg-mx-2 mt-2`}>
                        <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftProjectBtn} style={{zIndex: 1}}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(-29px, 0) rotate(180deg)'
                            }} data-color={'white'} onClick={() => {
                                if (projectScrollElem.current) {
                                    projectScrollElem.current.scrollTo({
                                        left: projectScrollElem.current.scrollLeft - 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>

                        <div className={`row x-auto left top noshrink nowrap nogap w-flex px-2`} ref={projectScrollElem}
                            onScroll={() => {
                                if (projectScrollElem.current && 
                                    projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                    if (IsLeftScroll(projectScrollElem.current)) {
                                        setShowLeftProjectBtn(true);
                                    } else {
                                        setShowLeftProjectBtn(false);
                                    }
                                    if (IsRightScroll(projectScrollElem.current)) {
                                        setShowRightProjectBtn(true);
                                    } else {
                                        setShowRightProjectBtn(false);
                                    }
                                }
                            }} >
                            {context.profile?.projects.map((elem : any, index: number) => {return (
                                <SiteCard project={elem} key={index} className={`${!!index ? `ml-2` : ``}`} 
                                          isEditable={!props.other} onUpdate={() => {
                                                context.UpdateData();
                                          }} desktop={true} orderNumber={index}/>
                            )})}
                        </div> 

                        <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightProjectBtn}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(29px, 0)'
                            }} data-color={'white'} onClick={() => {
                                if (projectScrollElem.current) {
                                    projectScrollElem.current.scrollTo({
                                        left: projectScrollElem.current.scrollLeft + 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>
                    </div> 
                </div>}

                {!props.other && cookie[`user_id_cookie`] === context.profile?.id && 
                (!context.profile?.about || context.profile?.projects?.length === 0) &&
                <div className={`block b-3 desktop w-flex mt-3 column p-3`} 
                        data-color={`extra-light-coral`} data-border={`coral-3`} style={{
                            justifyContent: `center`, 
                            minHeight: 229,
                        }}>
                    <p data-size={4} data-color={`coral`} className={`semibold seminarrow center`} style={{maxWidth: 418}}>
                        {(context.profile?.requests?.length === 0 && false) ? `Post at least one request to receive tailored matches from Osmos` : (
                        !context.profile?.about ? `Tell about yourself` : (
                        context.profile?.projects?.length === 0 && `Add your project`))}
                    </p>
                    {!!context.profile?.about && context.profile?.projects?.length === 0 &&
                    <p data-size={8} className={`mt-1 center`} data-color={`coral`}>
                        {`Projects help other people`}<br/>{`understand who you are.`}
                    </p>}
                    <button className={`block btn-flex desktop mt-3 row center`} onClick={() => {
                            if (context.profile?.requests?.length === 0 && false) {
                                context.setAddRequestPopup(true);
                            } else if (!context.profile?.about) {
                                context.setPage(0, 3);
                                context.setTab(3, 0);
                            } else {
                                context.setAddProjectPopup(true);
                            }
                        }} data-color={`coral`} style={{minWidth: 169}}>
                        <p>
                            {(context.profile?.requests?.length === 0 && false) ? `Post a request` : (
                            !context.profile?.about ? `Add about` : (
                            context.profile?.projects?.length === 0 && `Add a project`))}
                        </p>
                    </button>
                </div>}

                {context.isSubscriptionActive && props.other &&
                <div className={`block p-2 b-3 w-flex mt-3`} ref={recommendScrollBlock}
                        onMouseEnter={() => {
                        if (recommendScrollElem.current && 
                            recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                            if (IsLeftScroll(recommendScrollElem.current)) {
                                setShowLeftRecommendBtn(true);
                            }
                            if (IsRightScroll(recommendScrollElem.current)) {
                                setShowRightRecommendBtn(true);
                            }
                        }
                        }} onMouseLeave={() => { 
                        setShowLeftRecommendBtn(false); 
                        setShowRightRecommendBtn(false);
                    }}>
                    <div className={`row left nowrap px-1 pt-1`}>
                        <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={`black`}>
                            {!!context.recommendationsCommon?.length ? 
                            <p data-size={6} className={`narrow px-1`}>{context.recommendationsCommon?.length}</p> :
                            <Loading color={`white`} width={14} height={14}/>}
                        </div>
                        <p data-size={5} className={`seminarrow semibold`}>
                            {cookie[`user_id_cookie`] === context.profile?.id ? `Similar to you` : `People similar to ${context.profile?.name}`}
                        </p>
                    </div>
                    <div className={`row nogap nowrap min-w-flex neg-mx-2 mt-2`}>
                        <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftRecommendBtn} style={{zIndex: 1}}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(-29px, 0) rotate(180deg)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft - 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>

                        <div className={`row x-auto left top noshrink nowrap nogap w-flex px-2`} ref={recommendScrollElem}
                            onScroll={() => {
                                if (recommendScrollElem.current && 
                                    recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                    if (IsLeftScroll(recommendScrollElem.current)) {
                                        setShowLeftRecommendBtn(true);
                                    } else {
                                        setShowLeftRecommendBtn(false);
                                    }
                                    if (IsRightScroll(recommendScrollElem.current)) {
                                        setShowRightRecommendBtn(true);
                                    } else {
                                        setShowRightRecommendBtn(false);
                                    }
                                }
                            }}>
                            {context.recommendationsCommon?.length ?
                            context.recommendationsCommon.map((elem: any, index: number) => {return (
                                <SmallUserBlock profile={elem?.user} index={index} className={`${!!index ? `ml-2` : ``}`} 
                                                setRecommendations={context.setRecommendationsCommon} key={index}
                                                onClick={() => { 
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem?.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    } 
                                                }} color={`light-gray`}/>
                            )}) :
                            [1, 2, 3].map((elem, index) => {return (
                                <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}
                                     data-border={`gray-1`}>
                                    <NewAvatar size={0} className={`mb-2 circled`}/>
                                    <div style={{height: 61}}>
                                        <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                        <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                    </div>
                                    <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                </div>
                            )})}
                        </div> 

                        <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightRecommendBtn}>
                            <button className={`block btn centered w-4 h-4 b-4`} style={{
                                boxShadow: '0 0 13px #00000020',
                                transform: 'translate(29px, 0)'
                            }} data-color={'white'} onClick={() => {
                                if (recommendScrollElem.current) {
                                    recommendScrollElem.current.scrollTo({
                                        left: recommendScrollElem.current.scrollLeft + 278, 
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                            </button>
                        </div>
                    </div> 
                </div>}
                </>}

                {tab === 2 && !!subscriptions?.followers?.length && <>
                <div className={`block p-3 b-3 mb-2 row nowrap left`}>
                    <img src={LensBlack} alt={``} className={`w-3`}></img>
                    <input style={{backgroundColor: `transparent`}} placeholder={`Search`} onChange={e => { setSearch(e.target.value) }}></input>
                </div>
                <div className={`block p-3 b-3 w-flex mb-3 y-auto`} style={{maxHeight: 598}}>
                    {FilterList(subscriptions?.followers, search).map((elem: any, index: number) => {return (
                        <a href={`/profile/${elem.unique_id ?? elem.id}`} className={`block ${!!index ? `mt-3` : ''} row nowrap left`} key={index}>
                            <NewAvatar name={elem.name} avatar={elem.avatar?.link} type={elem.avatar?.style} size={5}/>
                            <div>
                                <p data-size={4} className={`semibold seminarrow`} data-lines={1}>{elem.name}</p>
                                <p data-size={5} data-color={`black-40-opacity`} data-lines={1}>{elem.occupation}</p>
                            </div>
                        </a>
                    )})}
                </div>
                </>}

                {tab === 3 && !!subscriptions?.follow?.length && <>
                <div className={`block p-3 b-3 mb-2 row nowrap left`}>
                    <img src={LensBlack} alt={``} className={`w-3`}></img>
                    <input style={{backgroundColor: `transparent`}} placeholder={`Search`} onChange={e => { setSearch(e.target.value) }}></input>
                </div>
                <div className={`block p-3 b-3 w-flex mb-3 y-auto`} style={{maxHeight: 598}}>
                    {FilterList(subscriptions?.follow, search).map((elem: any, index: number) => {return (
                        <a href={`/profile/${elem.unique_id ?? elem.id}`} className={`block ${!!index ? `mt-3` : ''} row nowrap left`} key={index}>
                            <NewAvatar name={elem.name} avatar={elem.avatar?.link} type={elem.avatar?.style} size={5}/>
                            <div>
                                <p data-size={4} className={`semibold seminarrow`} data-lines={1}>{elem.name}</p>
                                <p data-size={5} data-color={`black-40-opacity`} data-lines={1}>{elem.occupation}</p>
                            </div>
                        </a>
                    )})}
                </div>
                </>}
            </> : <>
                <div className={`skeleton w-flex b-3 h-8 mb-3`}></div>
                <div className={`skeleton w-flex b-3 h-8 mb-3`}></div>
            </>}
            </div>
        </div>  

        {mailOkPopup &&
        <InfoPopup _id={`mail-ok-popup`} show={mailOkPopup} setShow={setMailOkPopup}
                title={`Check your email. We sent the magic link for authorization`}
                buttonTitle={`Ok, I got it!`} onOk={() => {}} desktop={true}/>}

        {mailErrorPopup &&
        <InfoErrorPopup show={mailErrorPopup} setShow={setMailErrorPopup}
                        title={`Something went wrong`}
                        buttonTitle={`Reload the page`} onOk={() => {
                                setMailErrorPopup(false);
                                window.location.reload();
                        }} desktop={true}/>}       

        {canHelpPopup &&
        <PopupCenterCanHelpLanding show={canHelpPopup} setShow={setCanHelpPopup} desktop={true} 
                                   request={canHelpRequest}/>}  
    </div>)
}

export const ProfileInvitesDesktop = (props: any) => {
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [ tab, setTab ] = useState<number>(0);
    const [ hintStep, setHintStep ] = useState<number>(0); 

    const [ inviteMessage, setInviteMessage ] = useState<string>('Hey, i’ve been using Osmos for a while and thought you would like it as well!');
    const [ inviteeEmail, setInviteeEmail ] = useState<string>('');
    const [ contacts, setContacts ] = useState<any[]>();
    const [ contactsLoaded, setContactsLoaded ] = useState<boolean>(false);
    const [ searchText, setSearchText ] = useState<string>(``);

    const [ newInvite, setNewInvite ] = useState<any>();
    const [ newInviteMail, setNewInviteMail ] = useState<any>();
    const [ invitePopup, setInvitePopup ] = useState<boolean>(false);

    const [ showCopied, setShowCopied ] = useState<boolean>(false);
    const [ rndColor, setRndColor ] = useState<string[]>([]);   

    const FilterList = (list: any[], search: string = '') => {
        return list.filter(val => (
            (val.user?.fname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.user?.lname ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.user?.name ?? '').toLowerCase().startsWith(search.toLowerCase()) ||
            (val.user?.email ?? '').toLowerCase().startsWith(search.toLowerCase())
        ))
    }

    useEffect(() => {
        (newInvite ?? []).forEach((elem: any) => {
            if (elem?.token) {
                if (window.isSecureContext) {
                    try {
                        Share(`${window.location.origin}?code=${elem?.token}`, () => {
                            setShowCopied(true);
                            setTimeout(() => {
                                setShowCopied(false);
                            }, 2000)
                        })
                    } catch (error) {
                        console.warn("Fail to share link:", error)
                        ClipBoard(`${window.location.origin}?code=${elem?.token}`, () => {
                            setShowCopied(true);
                            setTimeout(() => {
                                setShowCopied(false);
                            }, 2000)
                        })
                    }
                }
    
                props.setProfile((val: any) => SetDict(val, ['invites', `pending`], [elem].concat(val.invites.pending)));
                // props.setProfile((val: any) => SetDict(val, ['invites', `available`], val.invites.available - 1));
            }
        })
    }, [newInvite]);

    useEffect(() => {
        (contacts ?? [{
            user: {
                email: inviteeEmail,
                SendInvite: EmailRegExp.test(inviteeEmail),
            }
        }]).filter(val => val?.user?.sendInvite).forEach((elem: any, index: number) => {
            if ((newInviteMail ?? [])[index]?.token) {
                if (elem?.user?.email && inviteMessage) {
                    SendInvite(
                        elem?.user?.email,
                        inviteMessage,
                        `${window.location.origin}?code=${newInviteMail[index]?.token}`,
                        () => {  }
                    )
                }

                props.setProfile((val: any) => SetDict(val, ['invites', `pending`], [newInviteMail[index]].concat(val.invites.pending)));
                // props.setProfile((val: any) => SetDict(val, ['invites', `available`], val.invites.available - 1));
            }
        })
    }, [newInviteMail]);

    useEffect(() => {
        setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);
        ContactsRequest(cookie[`user_id_cookie`], setContacts, false, () => {
            setContactsLoaded(true);
        }, () => {
            setContactsLoaded(true);
        });
    }, [])  

    useEffect(() => {
        if (rndColor) {
            const btn = document.querySelector(`#footer-button-color`) as HTMLButtonElement;
            // console.warn(`feed changed`, rndColor[0])
            if (btn) {
                btn.setAttribute(`footer-color`, rndColor[0] ?? ``);
                btn.click();
            }
        }
    }, [rndColor])

    useEffect(() => {
        if (props.profile?.invites) {
            if (!props.profile?.invites?.accepted?.length) {
                setTab(1);
            } else if (!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length) {
                setTab(0);
            }
        }
    }, [props.profile])

    useEffect(() => {
        if (!(props.profile?.first_login ?? [`invites`]).includes('invites')) {          
            // document.documentElement.style.overflowY = 'hidden';
            setSearchParams({first_login: `true`});
        }
    }, [props.profile])

    return (<div className={`w-flex column top`}>
        <div className={`w-flex md-w-10 sm-w-flex row grid-2-1 md-grid-1 gap-3 left top pb-5 px-6 md-px-0`}>
            <div>
                {contactsLoaded ?
                (!contacts?.length ? 
                <div className={`block b-3 p-3`} data-color={rndColor[0]} hidden={false}>               
                    <form className={`w-flex`} onSubmit={e => {
                        e.preventDefault();
                        if (inviteMessage && EmailRegExp.test(inviteeEmail)) {
                            InviteTokenUse(setNewInviteMail, null, 1, {
                                200: () => {
                                    (e.target as Element).classList.remove(`invalid-form`);
                                }
                            })
                        }
                    }} id={`invite-mail-form`}>
                        <div className={`row top nowrap w-flex mb-2 pl`}>
                            <div>
                                <p data-size={4} className={`semibold seminarrow mb`}>{`Invite my contacts`}</p>
                            </div>
                            {!contacts &&
                            <button className={`block btn-circled-32 desktop`} data-color={`black`} type={`button`}
                                    onClick={() => { ContactsRequest(cookie[`user_id_cookie`], setContacts) }}>
                                <p>{`Browse my contacts`}</p>
                            </button>}
                        </div>

                        <input className={`desktop mb-1 ${!EmailRegExp.test(inviteeEmail) ? 'in' : ''}valid`} type={`email`} required={true} placeholder={`Enter email`}
                                value={inviteeEmail} onChange={e => {setInviteeEmail(e.target.value)}}></input> 
                        <p className={`after neg-mt-1 mb-1 pt`} data-color={`coral`}></p>

                        <textarea className={`mobile neg-mb b-1 h-8 p-2 ${!inviteMessage ? 'in' : ''}valid`} required={true}
                                value={inviteMessage} onChange={e => {setInviteMessage(e.target.value)}}></textarea> 
                        <p className={`after pt`} data-color={`coral`}></p>

                        <button className={`block btn-circled-40 desktop mt-2`} data-color={`black`} type={`submit`} onClick={() => {
                            const mailForm = document.querySelector(`#invite-mail-form`);
                            if (mailForm) {
                                mailForm.classList.add(`invalid-form`);
                            }
                        }} disabled={!inviteMessage || !inviteeEmail}>
                            <p className={`semibold`} data-color={`white`}>{`Send invite`}</p>
                        </button>
                    </form>              
                </div> :
                <div className={`block p-3 b-3 w-flex`} data-color={rndColor[0]}>
                    <div className={`row top nowrap w-flex mb-2 pl`}>
                        <div>
                            <p data-size={4} className={`semibold seminarrow mb`}>{`Invite my contacts`}</p>
                        </div>
                        <button className={`block btn-circled-32 desktop`} data-color={`black`}
                                data-bg-color={rndColor[0]}
                                onClick={() => { 
                                    setInvitePopup(true);  
                                }} disabled={contacts.filter(val => val?.user?.sendInvite)?.length === 0}>
                            <p>{`Continue`}</p>
                        </button>
                    </div>
                    <input className={`block desktop mb`} type={`email`} required={true} placeholder={`Search by name or email`}
                           value={searchText} onChange={e => {setSearchText(e.target.value)}} data-color={`white`}></input> 

                    <div className={`block py-1 px-2 b-2`}>
                        {!!contacts?.length ?
                        FilterList(contacts, searchText).map((elem: any, index: number, array: any[]) => {
                        const prev = !array[index - 1]?.user?.sendInvite !== !elem?.user?.sendInvite || !index;
                        const next = !array[index + 1]?.user?.sendInvite !== !elem?.user?.sendInvite || index + 1 === array?.length;
                        return (<Fragment key={index}>
                            <div className={`block w-flex`} data-color={`gray`} 
                                style={{height: 1, marginTop: -1}} hidden={!index || prev}></div>
                            <button className={`block btn desktop row nowrap w-flex p-1 ${(prev && next) ? `b-1` : (prev ? `bt-1` : (next ? `bb-1` : ``))}`} key={index}
                                        onClick={() => { setContacts(
                                            val => SetDict(val, [index, "user", "sendInvite"], !elem?.user?.sendInvite)
                                        ) }} data-color={elem?.user?.sendInvite ? `light-gray` : null}>
                                <div className={`row nowrap left`}>
                                    <NewAvatar size={3} avatar={elem?.user?.avatar?.link} type={0} className={`not-active`}/>
                                    <p data-size={8} className={`semibold seminarrow not-active`}>{elem?.user?.email}</p>
                                </div>
                                <div className={`checkbox-radio w-3 h-3 b-2 centered noshrink`} data-checked={elem?.user?.sendInvite}>
                                    <div className={`checkbox-center h-1 w-1 b`}></div>
                                </div>
                            </button>
                        </Fragment>)}) :
                        [1, 2, 3].map((elem, index) => {return (
                            <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}>
                                <NewAvatar size={0} className={`mb-2 circled`}/>
                                <div className={`h-5`}>
                                    <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                    <div className={`skeleton b-3 h-2 mb-1`} style={{width: 195}}></div>
                                </div>
                                <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                            </div>
                        )})}
                    </div> 
                </div>) : 
                <div className={`w-flex skeleton h-7 b-3`}></div>}
            </div>

            <div>
                <div className={`block b-3 p-2 mb-3 w-flex`} data-with-hint_={searchParams.get(`first_login`) === `true`} id={`invites-block-hint`}>
                    <HintBlock breakSize={`md`} title={`Bring your friends to Osmos`} buttonTitle={`Ready to start`}
                                description={`Osmos feed includes only requests from people you are interested in.`}
                                onClick={() => {
                                    setHintStep(1);
                                }} show={hintStep === 0}/>

                    <HintBlock breakSize={`md`} title={`Bring your friends to Osmos`} buttonTitle={`Good idea`}
                                description={`and never miss an opportunity shared\xa0by\xa0them.`}
                                onClick={() => {
                                    setHintStep(2);
                                }} show={hintStep === 1}/>

                    <HintBlock breakSize={`md`} title={`Bring your friends to Osmos`} buttonTitle={`Finally`}
                                description={`Offer help, make intros, give — and you will receive much more in return.`}
                                onClick={() => {
                                    FirstLoginAdd(
                                        cookie[`user_id_cookie`],
                                        `invites`, () => {
                                            props.setProfile((val: any) => SetDict(val, [`first_login`], val.first_login.concat([`invites`])))
                                            setSearchParams({first_login: `false`});                    
                                            document.documentElement.style.overflowY = 'unset';
                                        }
                                    );
                                    setHintStep(0);
                                }} show={hintStep === 2}/>
                    <div className={`p w-flex`}>
                        {newInvite ? <>
                        <input className={`mobile ellipsis mb-2`} readOnly={true} type={`text`} required={true} placeholder={`${window.location.origin}?code=${newInvite?.token ?? `...`}`}
                            value={(newInvite ?? [])[0]?.token ? `${window.location.origin}?code=${(newInvite ?? [])[0]?.token ?? `...`}` : ``} data-size={6}></input>
                        <button className={`block btn-circled-32 desktop`} data-color={`light-gray`} onClick={() => {
                            if (window.isSecureContext) {                  
                                navigator.clipboard.writeText(`${window.location.origin}?code=${(newInvite ?? [])[0]?.token}`)
                                .then(() => {;
                                    setShowCopied(true);
                                    setTimeout(() => {
                                        setShowCopied(false);
                                    }, 2000)
                                    setNewInvite(undefined);
                                })
                            }
                        }}>
                            <p>{`Copy the invite link`}</p>
                        </button></> : 
                        <button className={`block btn-circled-32 desktop`} data-color={`light-gray`} onClick={() => { InviteTokenUse(setNewInvite) }}>
                            <p>{`Generate an invite link`}</p>
                        </button>}
                    </div>
                </div>

                <div className={`w-flex`} hidden={
                    !props.profile?.invites?.accepted?.length && 
                    !(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length
                }>
                    <div className={`row w-flex-p-3 left nowrap px-3 mb-3 neg-mx-3`}>
                        {!!props.profile ? <>
                            <button className={`block btn-tab-new desktop row nowrap`} data-color={tab === 0 ? `black` : `white`}
                                    onClick={() => { setTab(0) }} disabled={!props.profile?.invites?.accepted?.length}>
                                <p>{`Accepted`}</p>
                                {!!props.profile?.invites?.accepted?.length && 
                                <div className={`block min-w-3 h-3 b-3 centered not-active`} data-color={`${tab === 0 ? 'white' : `black`}-40-opacity`}>
                                    <p data-size={6} className={`narrow not-active`} data-color={`white`}>{props.profile?.invites?.accepted?.length}</p>
                                </div>}
                            </button>
                            <button className={`block btn-tab-new desktop row nowrap`} data-color={tab === 1 ? `black` : `white`}
                                    onClick={() => { setTab(1) }} disabled={!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length}>
                                <p>{`Pending`}</p>
                                {!!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length && 
                                <div className={`block min-w-3 h-3 b-3 centered not-active`} data-color={`${tab === 1 ? 'white' : `black`}-40-opacity`}>
                                    <p data-size={6} className={`narrow not-active`} data-color={`white`}>{(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length}</p>
                                </div>}
                            </button>
                        </> : <>
                            <div className={`label btn-tab-new desktop skeleton`}>
                                <p>{`Accepted`}</p>
                            </div>
                            <div className={`label btn-tab-new desktop skeleton`}>
                                <p>{`Pending`}</p>
                            </div>
                        </>}
                    </div>
                    
                    {tab === 0 &&
                    <div className={`block b-3 p-3 w-flex ${!!props.profile?.invites?.accepted?.length ? `py` : ``}`} 
                         hidden={!props.profile?.invites?.accepted?.length}>
                        {/* {!props.profile?.invites?.accepted?.length && <>
                            <p data-size={5} className={`semibold mb-1`} data-color={`black`}>{`Accepted requests`}</p>
                            <p data-size={6} data-color={`black-10-opacity`} className={`semibold`}>{`Add your friends to feed`}</p>
                        </>} */}
                        {(props.profile?.invites?.accepted ?? []).map((elem: any, index: number) => {return (
                            <div className={`block b-2 p-2 w-flex mt-2`} data-color={`light-gray`} key={index}>
                                <div className={`row top nowrap mb-2`}>
                                    <p data-size={6} data-color={`black`}>
                                        {DateTimeFromString(elem?.time ?? Date.now() / 1000)}&nbsp;	
                                        <span data-color={`blue`}>{elem?.name ?? `User`}</span>
                                        {` joined Osmos.`}
                                    </p>
                                    <NewAvatar name={elem?.name ?? `. .`} type={elem?.avatar?.style ?? 0} 
                                            avatar={elem?.avatar?.link} size={4}/>
                                </div>
                                <div className={`row left`}>
                                    <button className={`block btn-circled-40 desktop centered`}
                                            onClick={() => {
                                                Subscribe(cookie['user_id_cookie'], elem?.id);
                                            }} disabled={elem?.followed}>
                                        <p>{`Add to feed`}</p>
                                    </button>
                                </div>
                            </div>
                        )})}
                    </div>}

                    {tab === 1 &&
                    <div className={`block b-3 p-2 w-flex ${!!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length ? `py` : ``}`} 
                        style={{overflow: `hidden`}}
                        hidden={!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length}>
                        {/* {!(props.profile?.invites?.pending ?? []).filter((val: any) => !val.hidden).length && <>
                            <p data-size={5} className={`semibold mb-1`} data-color={`black`}>{`Pending requests`}</p>
                            <p data-size={6} data-color={`black-10-opacity`} className={`semibold`}>{`Control your invites`}</p>
                        </>} */}
                        {(props.profile?.invites?.pending ?? []).map((elem: any, index: number) => {return (elem.hidden ? null :
                        <div className={`b-3 p pt-2 px w-flex`} key={index}
                            style={{
                                transition: `margin 0.3s ease-in-out`,
                            }} id={`pending-link-block-${elem?.token}`}>
                            <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>{DateTimeFromString(elem?.time ?? Date.now() / 1000)}</p>
                            <button className={`block btn-flex desktop row left nogap nowrap w-flex mb-2`} onClick={() => {
                                if (window.isSecureContext) {                  
                                    navigator.clipboard.writeText(`${window.location.origin}?code=${elem?.token}`)
                                    .then(() => {;
                                        setShowCopied(true);
                                        setTimeout(() => {
                                            setShowCopied(false);
                                        }, 2000)
                                    })
                                }
                            }} data-color={`light-gray`}>
                                <p data-size={6} className={`w-flex regular pr-4 ellipsis`} data-color={`black`}>{`${window.location.origin}?code=${elem?.token}`}</p>
                                <img className={`w-3 neg-ml-3 hover-icon`} src={LinkBlack} alt={""}></img>
                            </button>
                            <button className={`block btn-circled-32 desktop`} data-color={`light-gray`}
                                    onClick={() => {
                                        InviteTokenBurn(elem?.token, () => {
                                            const pending_invite_elem = document.querySelector(`#pending-link-block-${elem?.token}`);
                                            if (pending_invite_elem) {
                                                (pending_invite_elem as HTMLDivElement).style.marginLeft = `800px`;
                                                setTimeout(() => {
                                                    (pending_invite_elem as HTMLDivElement).style.marginTop = `-${pending_invite_elem.scrollHeight}px`;
                                                    setTimeout(() => {
                                                        props.setProfile((val: any) => SetDict(val, [`invites`, `pending`, val.invites.pending.findIndex((val: any) => val.token === elem?.token), 'hidden'], true));
                                                    }, 300)
                                                }, 300) 
                                            }
                                        })
                                    }}>
                                <p>{`Revoke the link`}</p>
                            </button>
                            <div className={`block w-flex-p neg-mx mt-2 neg-mb`} data-color={`gray`} 
                                style={{height: 1}}></div>
                        </div>)})}
                        <div className={`block w-flex-p neg-mx`}
                            style={{height: 1, marginTop: -1}}></div>
                    </div>}
                </div>
            </div>
        </div>

        {invitePopup &&
        <PopupCenterInvite show={invitePopup} setShow={setInvitePopup}
                           invitees={contacts} setInvitees={setContacts} 
                           onFailure={() => {}} message={inviteMessage} setMessage={setInviteMessage}
                           setNewInvite={setNewInviteMail}/>}

        <div className={`block p-3 b-3 row top nogap nowrap`} data-color={`black`} style={{
            position: 'fixed',
            zIndex: 20,
            bottom: showCopied ? 30 : -200,
            right: 30,
            width: 300,
            transition: `bottom 0.3s ease-in-out`,
        }} id={`successfully-copied-label`}>                
            <div className={`btn-symbol filled w-3 h-3 centered noshrink mr-2`} data-color={`green`}><img src={OkWhite} alt={`ok`}></img></div>
            <p data-size={5} className={`semibold w-flex`} data-color={`white`}>
                {`Link successfully copied to clipboard`}
            </p>
        </div>  
    </div>)
}

export const ProfileSettingsDesktop = (props: any) => {
    const [ searchParams ] = useSearchParams();
    const context = useContext(ProfileContext);
    const globalContext = useContext(GlobalContext);

    const [ settingsPage, setSettingsPage ] = useState<number>(0);
    const [ popupRight, setPopupRight ] = useState<number | null>(null);
    const [ popupUnsubscribeAll, setPopupUnsubscribeAll ] = useState<number>(0);
    const [ popupLogOut, setPopupLogOut ] = useState<boolean>(false);
    const [ popupDelete, setPopupDelete ] = useState<boolean>(false);
    const [ confirmEmailPopup, setConfirmEmailPopup ] = useState<boolean>(false);
    const [ waitPaymentPortal, setWaitPaymentPortal ] = useState<boolean>(false);

    const [ emailPrefs, setEmailPrefs ] = useState<any>();
    
    useEffect(() => {
        setEmailPrefs({
            projectUpdates: {
                enabled: !!props?.profile?.emails_project_news,
                title: `Keep me updated with news about the project`,
            },
            platformUpdates: {
                enabled: !!props?.profile?.emails_platform_updates,
                title: `Get platform updates`,
            },
            calendarUpdates: {
                enabled: !!props?.profile?.emails_matches_calendar_reminders,
                title: `Notify me about my matches in my email`,
            },
            allUpdates: {
                enabled: true,
                title: ``,
            },
        })
    }, [props.profile])

    useEffect(() => {
        if (searchParams.get(`target`) === `support`) {
            setSettingsPage(1);
        }
    }, [])

    return (
        <div className={`row center w-flex`}>
            <div className={`w-10 sm-w-flex`}>
                {(settingsPage === 0) &&
                <div>
                    <div className={`py-1 mb-3`}>
                        <button className={`btn-circled-24 desktop p-left row left`} onClick={() => { context.setTab(0) }}>
                            <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                            <p data-size={4}>{`Settings`}</p>
                        </button>
                    </div>

                    <button className={`block btn-block-new b-2 desktop w-flex row mb-1 p-3`} onClick={() => { setSettingsPage(1) }}>
                        <p>{`Support`}</p>
                        <img src={SupportBlack} className={`h-3`} alt={``}></img>
                    </button>

                    <button className={`block btn-block-new b-2 desktop w-flex row mb-1 p-3`} onClick={() => { setPopupRight(1) }}>
                        <p>{`Privacy policy`}</p>
                        <img src={ProtectBlack} className={`h-3`} alt={``}></img>
                    </button>

                    <button className={`block btn-block-new b-2 desktop w-flex row mb-3 p-3`} onClick={() => { setPopupRight(3) }}>
                        <p>{`User agreement`}</p>
                        <img src={AgreementBlack} className={`h-3`} alt={``}></img>
                    </button>

                    <button className={`block btn-block-new b-2 desktop w-flex row mb-1 p-3`} onClick={() => { setSettingsPage(2) }}>
                        <p>{`Profile settings`}</p>
                        <img src={SettingsBlack} className={`h-3`} alt={``}></img>
                    </button>

                    <button className={`block btn-block-new b-2 desktop w-flex row p-3`} onClick={() => { setPopupLogOut(true) }}>
                        <p>{`Log out`}</p>
                        <img src={ExitBlack} className={`h-3`} alt={``}></img>
                    </button>
                </div>}
                
                {(settingsPage === 1) &&
                <div>
                    <div className={`py-1 mb-3`}>
                        <button className={`btn-circled-24 desktop p-left row left`} onClick={() => { setSettingsPage(0) }}>
                            <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                            <p data-size={4}>{`Support`}</p>
                        </button>
                    </div>

                    <div className={`block p-3 b-3 w-flex`}>
                        <p data-size={4} className={`seminarrow semibold mb-3`}>{`Email us if you have any questions`}</p>
                        
                        <button className={`block btn-flex desktop row w-flex`} data-color={'light-gray'} onClick={() => {
                                    navigator.clipboard.writeText(`support@osmos.social`).then(() => {
                                        (document.querySelector(`#successfully-copied-label-settings`) as HTMLDivElement).style.right = `58px`;
                                        setTimeout(() => {
                                            (document.querySelector(`#successfully-copied-label-settings`) as HTMLDivElement).style.right = `-310px`;
                                        }, 2000)
                                    });
                                }}>
                            <p className={`regular`}>{`support@osmos.social`}</p>
                            <img src={CopyBlack} alt={`copy`} title={``}></img>
                        </button>
                    </div>
                </div>}
                
                {(settingsPage === 2) &&
                <div>
                    <div className={`py-1 mb-3`}>
                        <button className={`btn-circled-24 desktop p-left row left`} onClick={() => { setSettingsPage(0) }}>
                            <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                            <p data-size={4}>{`Profile settings`}</p>
                        </button> 
                    </div>

                    <div className={`block p-3 b-3 w-flex mb-3`}>
                        <p data-size={4} className={`seminarrow semibold mb-3`}>{`Email preferences`}</p>

                        {[`calendarUpdates`, `projectUpdates`, `platformUpdates`].map((elem, index) => {return (
                            <button className={`btn-checkbox desktop w-flex row left top nowrap mb-1`} data-selected={emailPrefs[elem].enabled} key={index}
                                    onClick={() => {
                                        setEmailPrefs((val: any) => SetDict(val, [elem, `enabled`], !val[elem].enabled));
                                    }}>
                                <div className={`checkbox-radio h-3 w-3 b centered noshrink`} data-checked={emailPrefs[elem].enabled}>
                                    <img src={OkWhite} alt={`v`}></img>
                                </div>
                                <p data-size={5}>{emailPrefs[elem].title}</p>
                            </button>
                        )})}

                        <div className={`row nowrap mt-3`}>
                            <button className={`block btn-rectangle-40 desktop row center w-flex`} data-color={'black'} 
                                    onClick={() => {
                                        EmailPrefsUpdate(props?.profile?.id, {
                                            "emails_matches_calendar_reminders": emailPrefs[`calendarUpdates`].enabled,
                                            "emails_project_news": emailPrefs[`projectUpdates`].enabled,
                                            "emails_platform_updates": emailPrefs[`platformUpdates`].enabled,
                                        }, () => {
                                            props.setProfile((val: any) => SetDict(val, ["emails_matches_calendar_reminders"], emailPrefs[`calendarUpdates`].enabled));
                                            props.setProfile((val: any) => SetDict(val, ["emails_project_news"], emailPrefs[`projectUpdates`].enabled));
                                            props.setProfile((val: any) => SetDict(val, ["emails_platform_updates"], emailPrefs[`platformUpdates`].enabled));
                                        }, () => {}, () => {}, () => {}, () => {
                                            //confirmed
                                        }, () => {
                                            setConfirmEmailPopup(true);
                                        })
                                    }}>
                                <p>{`Save changes`}</p>
                            </button>

                            <button className={`block btn-rectangle-40 desktop row center w-flex`} data-color={'light-gray'} 
                                    onClick={() => { setPopupUnsubscribeAll(1) }}>
                                <p>{`Unsubscribe from all marketing emails`}</p>
                            </button>
                        </div>
                    </div> 
                    
                    {!globalContext?.community && (!context.profile?.subscription?.is_stripe_customer ?
                    <button className={`block btn-block-new b-2 desktop w-flex row mb-1 p-3`} onClick={() => {
                        setWaitPaymentPortal(true);
                        OpenStripePortal(false, () => { setWaitPaymentPortal(false) });
                    }} hidden={context.profile?.subscription?.is_subscription_active}>
                        <p>{`Activate subscription`}</p>
                        {waitPaymentPortal ? <Loading width={24} height={24} color={`black`}/> :
                        <img src={CardBlack} className={`h-3`} alt={``}></img>}
                    </button> :
                    <button className={`block btn-block-new b-2 desktop w-flex row top mb-1 p-3`} onClick={() => {
                        setWaitPaymentPortal(true);
                        OpenStripePortal(true, () => { setWaitPaymentPortal(false) });
                    }}>
                        <div>
                            <p className={`mb-1`}>
                                {`My subscription`}
                            </p>
                            <p data-color={`black-40-opacity`}>
                                {`Expires ${DateFromString(context.profile?.subscription?.expire_at)}`}
                            </p>
                        </div>
                        {waitPaymentPortal ? <Loading width={24} height={24} color={`black`}/> :
                        <img src={CardBlack} className={`h-3`} alt={``}></img>}
                    </button>)}

                    <button className={`block btn-block-new b-2 desktop w-flex row p-3`} onClick={() => { setPopupDelete(true) }}>
                        <p>{`Delete account`}</p>
                        <img src={TrashBlack} className={`h-3`} alt={``}></img>
                    </button>                  
                </div>}

                <div className={`block p-3 b-3 row top nogap nowrap`} data-color={`black`} style={{
                    position: 'fixed', zIndex: 51,
                    right: -310,
                    bottom: 58,
                    width: 300,
                    transition: `right 0.3s ease-in-out`,
                }} id={`successfully-copied-label-settings`}>
                    <div className={`block w-3 h-3 b-2 centered neg-mr-3`} data-color={`green`}>
                        <img src={OkWhite} alt={`v`} className={`h-2`}></img>
                    </div>
                    <p data-size={5} className={`semibold w-flex pl-4`} data-color={`white`}>{`Link successfully copied to clipboard`}</p>
                </div>
            </div>

            {!!popupUnsubscribeAll && 
            <PopupCenterUnsubscribe show={!!popupUnsubscribeAll} setShow={(bool: boolean) => {
                                        setPopupUnsubscribeAll(val => bool ? val : 0);
                                    }}
                                    onUnsubscribeAll={() => {
                                        EmailPrefsUpdate(props?.profile?.id, {
                                            "emails_matches_calendar_reminders": false,
                                            "emails_project_news": false,
                                            "emails_platform_updates": false,
                                        }, () => {
                                            props.setProfile((val: any) => SetDict(val, ["emails_matches_calendar_reminders"], false));
                                            props.setProfile((val: any) => SetDict(val, ["emails_project_news"], false));
                                            props.setProfile((val: any) => SetDict(val, ["emails_platform_updates"], false));
                                            setPopupUnsubscribeAll(2);
                                        }, () => {}, () => {}, () => {}, () => {
                                            //confirmed
                                        }, () => {
                                            setConfirmEmailPopup(true);
                                        })
                                    }} thanks={popupUnsubscribeAll === 2} profile={props.profile}/>}

            {!!popupLogOut && 
            <PopupCenterLogOut show={popupLogOut} setShow={setPopupLogOut} profile={props.profile}/>}

            {!!popupDelete && 
            <PopupCenterDelete show={popupDelete} setShow={setPopupDelete} profile={props.profile}/>}

            {!!popupRight &&
            <PopupRightSettings settingsTab={popupRight} setSettingsTab={setPopupRight} profile={props.profile}/>}

            {confirmEmailPopup &&
            <PopupCenter show={confirmEmailPopup} setShow={setConfirmEmailPopup} desktop={true} onClose={() => {}}/>}
        </div>
    )
}

export const ProfileNotificationsDesktop = (props: any) => { 
    const context = useContext(ProfileContext);

    useEffect(() => {
        NotificationsRead(`all`, () => {
            (context.notifications ?? []).forEach((elem: any, index: number) => {
                context.setNotifications((val: any) => SetDict(val, [index, `isRead`], true));
            })
        })
    }, [])
    
    return (
        <div className={`row center w-flex`}>
            <div className={`w-10 sm-w-flex`}>
                <div>
                    <div className={`py-1 mb-3`}>
                        <button className={`btn-circled-24 desktop p-left row left`} onClick={() => { context.setTab(0) }}>
                            <img src={AngleRightBlack} alt={`>`} style={{transform: `rotate(180deg)`}} className={`h-3`}></img>
                            <p data-size={4}>{`Notifications`}</p>
                        </button>
                    </div>

                    {!!context.notifications ? (!!context.notifications?.length ?
                    <div className={`w-flex b-3 p-4 block mb-6`}>
                    {(context.notifications ?? []).map((elem: any, index: number) => {return (
                        <div className={`w-flex row left top nowrap ${!!index ? `mt-2` : ``}`} key={index}>
                            <div className={`h-5 w-3 centered noshrink neg-ml-3 neg-mr-1`} hidden={elem.is_read}>
                                <div className={`block w-1 h-1 b`} data-color={`coral`}></div>
                            </div> 
                            <NewAvatar size={5} name={`${elem.fname} ${elem.lname}`} type={elem.avatar?.style}
                                    avatar={elem.avatar?.link}/>
                            <div>                            
                                <p data-size={6} data-lines={3} className={`mb-1`}>
                                    <span className={`semibold`}>
                                        {elem?.type < 8 ? `${elem?.fname} ${elem.lname} ` : 
                                        elem?.type === 8 ? "" : "New interesting request "}
                                    </span>&nbsp;
                                    {elem.type === 0 ? `is ready to help you: ${elem.text}` :
                                    elem.type === 1 ? `is your new match!` :
                                    elem.type === 2 ? `sent you a new message: ${elem.text}` :
                                    elem.type === 3 ? `started following you!` :
                                    elem.type === 4 ? `posted a new request: ${elem.text}` : 
                                    elem.type === 5 ? `started following ${elem.text} new people. View ${elem.fname}'s following to find new connections.` :
                                    elem.type === 6 ? <>
                                        {`suggested you for `}
                                        <span className={`semibold`}>{`${elem.target?.name}'s `}</span>
                                        {`request: ${elem.text}`}
                                    </> : 
                                    elem.type === 7 ? <>
                                        {`suggested `}
                                        <span className={`semibold`}>{elem.target?.name}</span>
                                        {` for your request: ${elem.text}`}
                                    </> : 
                                    elem.type === 8 ? <>
                                        {`Seems like `} 
                                        <span className={`semibold`}>{`${elem?.fname} ${elem.lname}`}</span>
                                        {` and 5 more people `}
                                        <span className={`semibold`}>{`can help`}</span>
                                        {` you with your request`}
                                    </> : <>
                                        {`for you from `}
                                        <span className={`semibold`}>{`${elem?.fname} ${elem.lname}`}</span>
                                    </>}
                                </p>
                                <a className={`block btn-rectangle-24 desktop row`} 
                                data-color={(elem.type === 1 || elem.type === 2) ? `black` : `light-gray`} 
                                href={elem.type === 0 ? `/profile/chat?offer_id=0` :
                                        elem.type === 1 ? `/profile/chat?chat_id=${elem.target_id}` :
                                        elem.type === 2 ? (elem?.chat_offer ? `/profile/chat?offer_id=0` : `/messenger/${elem.target_id}`) :
                                        elem.type === 3 ? `/profile/${elem.target_id}` :
                                        elem.type === 4 ? `/request/${elem.target_id}` :
                                        elem.type === 5 ? `/profile/${elem.target_id}?target=following` : 
                                        elem.type === 6 ? `/request/${elem.target_id}` : 
                                        elem.type === 7 ? `/profile/${elem.target_id}` : 
                                        elem.type === 8 ? `/profile/${elem?.target_id}` : `/request/${elem?.target_id}`} 
                                style={{width: `max-content`}}>
                                    <p>{elem.type === 0 ? `Go to Responses to me` :
                                        elem.type === 1 ? `Go to chat` :
                                        elem.type === 2 ? `Go to chat` :
                                        elem.type === 3 ? `Go to profile` :
                                        elem.type === 4 ? `Go to request` :
                                        elem.type === 5 ? `Expand your network` :
                                        elem.type === 6 ? `Go to request` :
                                        elem.type === 7 ? `Go to profile` : 
                                        elem.type === 8 ? `Check recommendation` : `Check request`}</p>
                                </a>
                            </div>
                        </div>
                    )})}
                    </div> :
                    <MessageCardDesktop type={`notifications`} className={`mb-3`}/>) :
                    <div className={`skeleton w-flex b-3 h-9`}></div>}
                </div>
            </div>
        </div>
    )
}